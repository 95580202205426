import React, { useCallback, useEffect, forwardRef, useImperativeHandle  } from "react";
import { Unity } from "react-unity-webgl";
import { 
    AppProperties, 
    GUIProperties 
} from '../../Properties';
import { 
    guiActions 
} from '../../Slices/GuiSlice';
import { 
    gameActions, 
} from '../../Slices/GameSlice';

import { useDispatch } from 'react-redux';
import { useUnityContext } from "react-unity-webgl";

type GameProps = {};
    
export type GameHandle = {
    gameSendMessage: (functionName: string, payload: string) => void,
}

const Game: React.ForwardRefExoticComponent<React.RefAttributes<GameHandle>> = forwardRef<GameHandle, GameProps>((props, ref) => {

    const dispatch = useDispatch();

    const setGameProgression = (payload: number) => {
        dispatch(gameActions.setGameProgression(payload));
    }
    
    const setGameLoading = (payload: boolean) => {
        dispatch(gameActions.setGameLoading(payload));
    }

    const handleWebWorldToHideaway = useCallback((hideawayId: string)  => {
        dispatch(guiActions.setLoading(true));
        dispatch(guiActions.setWindowId(GUIProperties.Windows.Id.None));
        dispatch(guiActions.setAppState(AppProperties.AppStates.Hideaway));
        dispatch(guiActions.setLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const { unityProvider, sendMessage, addEventListener, removeEventListener, loadingProgression, isLoaded } = useUnityContext({
        loaderUrl: "Game/Build.loader.js",
        dataUrl: "Game/Build.data",
        frameworkUrl: "Game/Build.framework.js",
        codeUrl: "Game/Build.wasm",
        companyName: "Shmitec",
        productName: "Dead-Website",
        productVersion: "1.0.5"
    });

    useEffect(() => {
        addEventListener("WebWorldToHideaway", handleWebWorldToHideaway);
        return () => {
            removeEventListener("WebWorldToHideaway", handleWebWorldToHideaway);
        };
    }, [addEventListener, removeEventListener, handleWebWorldToHideaway]);

    useEffect(() => {
        setGameProgression(loadingProgression);
    }, [loadingProgression]);

    useEffect(() => {
        setGameLoading(!isLoaded);
    }, [isLoaded]);


    
    useImperativeHandle(ref, () => ({

        gameSendMessage(functionName: string, payload: string) {
            sendMessage("GameController", functionName, payload);
        }
    
    }));
    
    return (
        <Unity className="unity" unityProvider = { unityProvider } />
    );
});


/*
    const call_Characters_SelectCharacter = (name, sex) => {
        unityContext.send("GameController", "CharactersSelectCharacter", sex);
    };
*/


export default Game;