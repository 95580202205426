import { ErrorVO } from 'ts/Slices/AccountSlice';
import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword, signOut, UserCredential } from "firebase/auth";

export const signIn : (email: string, password: string) => Promise<ErrorVO | boolean> = async (email, password) => {
    return new Promise(async function(resolve, reject) {
        const auth = getAuth();
        signInWithEmailAndPassword(auth, email, password)
        .then((u: UserCredential) => {
            resolve(true);
        })
        .catch((error: string) => {
            resolve({ message : error });
        });
    });
};

export const register : (email: string, password: string) => Promise<ErrorVO | boolean> = async (email, password) => {
    return new Promise(async function(resolve, reject) {
        const auth = getAuth();
        createUserWithEmailAndPassword(auth, email, password)
        .then((u : UserCredential) => {
            resolve(true);
        })
        .catch((error: string) => {
            resolve({ message : error });
        });
    });
};

export const logout : () => Promise<ErrorVO | boolean> = async () => {
    return new Promise(async function(resolve, reject) {
        const auth = getAuth();
        signOut(auth)
        .then(() => {
            resolve(true);
        })
        .catch((error: string) => {
            resolve({ message : error });
        });
    });
};