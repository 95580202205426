import React from 'react';

import { guiActions } from 'ts/Slices/GuiSlice';

import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { RootState } from 'ts/Slices/Store';
import ModalWindow from 'ts/Components/Windows/ModalWindow';
import Button from 'ts/Components/Buttons/Button';

const ConfirmBox: React.FC = () => {
    
    const dispatch = useDispatch();

    const { t } = useTranslation();

    const confirmCallback = useSelector((state : RootState) => state.gui.confirmCallback);
    const confirmLabel = useSelector((state: RootState) => state.gui.confirmLabel);
        
    const handleConfirmClick = () => {
        confirmCallback();
    };

    const handleCloseClick = () => {
        dispatch(guiActions.hideConfirmBox());
    };

    
    return (
        <ModalWindow>
            <div className = {  "flex items-center max-w-sm mx-auto h-full"  }>
                <div className = { "flex flex-col border-2 border-brand-horror-teal-500 outline outline-2 outline-offset-2 hover:outline-offset-2 outline-brand-horror-cyan-500 hover:outline-brand-horror-cyan-700 bg-white p-4 w-full rounded-3xl" }>
                    <h3 className = { "text-center text-3xl text-brand-blood-600 mb-4" }>
                        { confirmLabel }
                    </h3>
                    <div  className = { "my-1 flex" } >
                        <Button additionalClassName = { "h-8 basis-6/12 mx-2" } 
                                handleClick = { handleConfirmClick }>
                            <span className = { "" }>
                                { t('app.yes') }
                            </span>
                        </Button>
                        <Button additionalClassName = { "h-8 basis-6/12 mx-2" } 
                                handleClick = { handleCloseClick }>
                            <span className = { "" }>
                                { t('app.no') }
                            </span>
                        </Button>
                    </div>
                </div>
            </div>
        </ModalWindow>
    );
}

export default ConfirmBox;