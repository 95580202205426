import React from 'react';

import { guiActions } from 'ts/Slices/GuiSlice';

import { useDispatch } from 'react-redux';
import FullscreenIcon from 'ts/Icons/Forms/FullscreenIcon';

const FullscreenButton: React.FC = () => {    

    const dispatch = useDispatch();

    const isInFullScreen = document.fullscreenElement; /* Standard syntax */
                    //|| document.webkitFullscreenElement /* Chrome, Safari and Opera syntax */
                    //|| document.mozFullScreenElement /* Firefox syntax */
                    //|| document.msFullscreenElement;

    const handleFullscreenChange = async() => {
        dispatch(guiActions.handleFullscreenChange());
    }
    

        return (
            <button 
                    onClick = { handleFullscreenChange }>
                <FullscreenIcon 
                        className="fill-white h-6 opacity-80 hover:opacity-100 cursor-brand-link" 
                        fullscreen={isInFullScreen !== null} />
            </button>
        );

            

};

export default FullscreenButton;