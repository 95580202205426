import React from 'react';

interface SaveIconProps {
    className: string;
}

const SaveIcon:React.FC<SaveIconProps> = (props) => {
    return ( 
        <svg className={props.className} viewBox="0 0 92 92" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M72.8333 0H3.83333C1.71733 0 0 1.71733 0 3.83333V88.1667C0 90.2827 1.71733 92 3.83333 92H88.1667C90.2827 92 92 90.2827 92 88.1667V19.1667L72.8333 0ZM23 11.5C23 9.384 24.7173 7.66667 26.8333 7.66667H65.1667C67.2827 7.66667 69 9.384 69 11.5V34.5C69 36.616 67.2827 38.3333 65.1667 38.3333H26.8333C24.7173 38.3333 23 36.616 23 34.5V11.5ZM76.6667 84.3333H15.3333V57.5C15.3333 55.384 17.0507 53.6667 19.1667 53.6667H72.8333C74.9493 53.6667 76.6667 55.384 76.6667 57.5V84.3333Z" fill="black"/>
            <path d="M61.3333 34.5H46V11.5H61.3333V34.5Z" fill="black"/>
        </svg>
    );
}

export default SaveIcon;