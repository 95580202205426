import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
    apiKey: "AIzaSyBv7A_URja1lycZTCQQSkpHkHKaylhhAsE",
    authDomain: "dead-website.firebaseapp.com",
    databaseURL: "https://dead-website.firebaseio.com",
    projectId: "dead-website",
    storageBucket: "dead-website.appspot.com",
    messagingSenderId: "303575400819",
    appId: "1:303575400819:web:43c9b34c943301fb048506",
    measurementId: "G-468TMQBT3L"
};


const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export default db;