import React, { Fragment } from 'react';

interface MenuIconProps {
    className: string;
    opened: boolean;
}

const MenuIcon:React.FC<MenuIconProps> = (props) => {
    return ( 
        <svg 
            className={props.className}            
            viewBox="0 0 92 92" 
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
                { !props.opened &&
                    <Fragment>
                        <path d="M80.5003 49.8332H11.5003C10.4837 49.8332 9.50864 49.4293 8.78975 48.7104C8.07086 47.9915 7.66699 47.0165 7.66699 45.9998C7.66699 44.9832 8.07086 44.0082 8.78975 43.2893C9.50864 42.5704 10.4837 42.1665 11.5003 42.1665H80.5003C81.517 42.1665 82.492 42.5704 83.2109 43.2893C83.9298 44.0082 84.3337 44.9832 84.3337 45.9998C84.3337 47.0165 83.9298 47.9915 83.2109 48.7104C82.492 49.4293 81.517 49.8332 80.5003 49.8332Z" fill="#046648"/>
                        <path d="M80.5003 72.8332L11.5226 72.8259C6.41148 72.8254 6.41148 65.1587 11.5226 65.1592L80.5003 65.1665C85.6114 65.167 85.6114 72.8337 80.5003 72.8332ZM80.4779 26.8332H11.5003C6.38921 26.8332 6.38921 19.1665 11.5003 19.1665H80.4779C85.589 19.1665 85.589 26.8332 80.4779 26.8332Z" fill="#024430"/>
                    </Fragment>
                }
                { props.opened &&
                    <Fragment>
                        <path d="M80.5003 49.8332H11.5003C10.4837 49.8332 9.50864 49.4293 8.78975 48.7104C8.07086 47.9915 7.66699 47.0165 7.66699 45.9998C7.66699 44.9832 8.07086 44.0082 8.78975 43.2893C9.50864 42.5704 10.4837 42.1665 11.5003 42.1665H80.5003C81.517 42.1665 82.492 42.5704 83.2109 43.2893C83.9298 44.0082 84.3337 44.9832 84.3337 45.9998C84.3337 47.0165 83.9298 47.9915 83.2109 48.7104C82.492 49.4293 81.517 49.8332 80.5003 49.8332Z" fill="#058860"/>
                        <path d="M80.5003 72.8332H34.5003C33.4837 72.8332 32.5086 72.4293 31.7897 71.7104C31.0709 70.9915 30.667 70.0165 30.667 68.9998C30.667 67.9832 31.0709 67.0081 31.7897 66.2893C32.5086 65.5704 33.4837 65.1665 34.5003 65.1665H80.5003C81.517 65.1665 82.492 65.5704 83.2109 66.2893C83.9298 67.0081 84.3337 67.9832 84.3337 68.9998C84.3337 70.0165 83.9298 70.9915 83.2109 71.7104C82.492 72.4293 81.517 72.8332 80.5003 72.8332ZM57.5003 26.8332H11.5003C10.4837 26.8332 9.50864 26.4293 8.78975 25.7104C8.07086 24.9915 7.66699 24.0165 7.66699 22.9998C7.66699 21.9832 8.07086 21.0082 8.78975 20.2893C9.50864 19.5704 10.4837 19.1665 11.5003 19.1665H57.5003C58.517 19.1665 59.492 19.5704 60.2109 20.2893C60.9298 21.0082 61.3337 21.9832 61.3337 22.9998C61.3337 24.0165 60.9298 24.9915 60.2109 25.7104C59.492 26.4293 58.517 26.8332 57.5003 26.8332Z"  fill="#08CD91" />
                    </Fragment>                    
                }
        </svg>

    );
}

export default MenuIcon;

<svg width="92" height="92" viewBox="0 0 92 92" fill="none" xmlns="http://www.w3.org/2000/svg">

</svg>
