import React from 'react';

import AirdropIcon from 'ts/Icons/Crypto/AirdropIcon';
import HeaderButton from 'ts/Components/Buttons/HeaderButton';

interface AirdropButtonProps {
    handleMenuAdvertClick: Function;
}

const AirdropButton:React.FC<AirdropButtonProps> = (props) => {
    
    return (
        <HeaderButton additionalClassName = { "h-12 w-12 flex items-center justify-center opacity-80 hover:opacity-100" }
            handleClick =  { props.handleMenuAdvertClick }
        >
            <AirdropIcon className={'fill-black h-10'} />
        </HeaderButton>
    );
}

export default AirdropButton;