import React from 'react';

interface ArrowRightIconProps {
    className: string;
}

const ArrowRightIcon:React.FC<ArrowRightIconProps> = (props) => {
    return (    
        <svg viewBox="0 0 20 20" 
                className={props.className}
                xmlns="http://www.w3.org/2000/svg">
           <path d="M11 16.5V13H3V7h8V3.5l6.5 6.5-6.5 6.5z" />
        </svg>
    );
}

export default ArrowRightIcon;