import React from 'react';

import { GUIProperties } from 'ts/Properties';

import { guiActions } from 'ts/Slices/GuiSlice';

import { useDispatch } from 'react-redux';
import CloseIcon from 'ts/Icons/Forms/CloseIcon';
import Button from 'ts/Components/Buttons/Button';

interface CloseButtonProps {
    handleCloseClick?: Function;
}

const CloseButton: React.FC<CloseButtonProps> = (props) => {
    
    const dispatch = useDispatch();

    const handleWindowClose = () => {
        if(props.handleCloseClick) {
            props.handleCloseClick();
        } else {
            dispatch(guiActions.setWindowId(GUIProperties.Windows.Id.None));
        }
    };

    return (
        <Button additionalClassName = { "float-right" } 
                handleClick = { handleWindowClose }>
            <CloseIcon className="h-8 fill-current" />
        </Button>
    );
};

export default CloseButton;

