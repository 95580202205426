import React, { useState } from 'react';

import { useSelector } from 'react-redux';
import { RootState } from 'ts/Slices/Store';

import LanguageButton from 'ts/Partials/Buttons/LanguageButton';

import { useTranslation } from 'react-i18next';
import BigWindow from 'ts/Components/Windows/BigWindow';
import VolumeIcon from 'ts/Icons/Forms/VolumeIcon';
import MusicIcon from 'ts/Icons/Forms/MusicIcon';


const Settings:React.FC = (props) => {
    
    
    const { t } = useTranslation("translations");

    const settings  = useSelector((state: RootState) => state.account.settings);

    const [soundValue, setSoundValue] = useState(30);
    const [musicValue, setMusicValue] = useState(30);

    /*
    const handleSettingsSaveClick = () => {
        dispatch(guiActions.setWindowId(GUIProperties.Windows.Id.Pause));
    };
    */
    
    const handleChangeSound = (e: React.FormEvent<HTMLInputElement>) => {
        const newValue = e.currentTarget.value;
        setSoundValue(Number(newValue));
    };

    const handleChangeMusic = (e: React.FormEvent<HTMLInputElement>) => {
        const newValue = e.currentTarget.value;
        setMusicValue(Number(newValue));
    };
    
    return (
        <BigWindow 
                title={ t('settings.title') }>
            <div className = { "grid grid-cols-3 items-center" }>
                <span className = { "text-black text-right mr-2 align-bottom my-2" }>
                    { t('settings.sounds') }
                </span>
                <input type="range"
                        value = { soundValue } 
                        className = { "m-0 w-[200px] align-middle" } 
                        onChange = { handleChangeSound } 
                        aria-labelledby = "continuous-slider" />
                <VolumeIcon percent={soundValue} className={'fill-black h-12 ml-2 my-2'} />


                <span className = { "text-black text-right mr-2 my-2" }>
                    { t('settings.music') }
                </span>
                <input type="range"
                        value = { musicValue } 
                        className = { ""/*classes.sliderStyle*/ } 
                        onChange = { handleChangeMusic } 
                        aria-labelledby="continuous-slider" />
                <MusicIcon percent={musicValue} className={'fill-black h-12 ml-2 my-2'} />

                <span className = { "text-black text-right mr-2" }>
                    { t('settings.language') }
                </span>
                <span className = { "w-full text-center text-brand-green-100" }>{ settings.language }</span>
                <div className = { " ml-2" }>
                    <LanguageButton />
                </div>
            </div>
        </BigWindow>
    );
    
}

export default Settings;
