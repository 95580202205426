import React from 'react';

interface FemaleIconProps {
    className: string;
}

const FemaleIcon:React.FC<FemaleIconProps> = (props) => {
    return (    
        <svg xmlns="http://www.w3.org/2000/svg"
				className={props.className}
	            viewBox="0 0 100 100">
        	<circle fill="#231F20" cx="49.244" cy="20.203" r="8.2"/>
        	<path fill="#231F20" d="M72.147,54.101l-5.47-20.414c-0.003-0.016-0.009-0.031-0.013-0.047l-0.096-0.359l-0.014,0.004
        		c-0.499-1.556-1.94-2.69-3.662-2.69c-0.013,0-0.026,0.004-0.039,0.004v-0.071H37.108v0.067c-1.722,0-3.163,1.134-3.662,2.69
        		l-0.014-0.004l-0.096,0.358c-0.004,0.017-0.01,0.032-0.013,0.049l-5.47,20.413l0.052,0.014c-0.025,0.169-0.052,0.337-0.052,0.513
        		c0,1.924,1.56,3.485,3.485,3.485c1.474,0,2.727-0.919,3.236-2.212l0.024,0.006l4.013-14.974v0.057h0.031
        		c0.11-0.493,0.529-0.869,1.054-0.869c0.526,0,0.945,0.377,1.055,0.869h0.033v0.161c0.002,0.025,0.014,0.046,0.014,0.071
        		s-0.013,0.046-0.014,0.071v0.153L34.36,65.424c-0.104,0.211-0.167,0.442-0.184,0.688l-0.014,0.051l0.008,0.002
        		c-0.001,0.027-0.008,0.053-0.008,0.081c0,1.033,0.838,1.87,1.871,1.87h4.751v15.849c0,2.226,1.805,4.032,4.032,4.032
        		c2.227,0,4.032-1.805,4.032-4.032l0-15.849h2.293v15.849c0,2.226,1.806,4.032,4.033,4.032c2.227,0,4.032-1.805,4.032-4.032V68.116
        		h4.765c1.033,0,1.871-0.837,1.871-1.87c0-0.028-0.007-0.054-0.008-0.081l0.008-0.002l-0.014-0.051
        		c-0.018-0.246-0.081-0.477-0.184-0.688L59.24,41.52l0.021-0.006c-0.027-0.095-0.059-0.188-0.059-0.291
        		c0-0.608,0.493-1.102,1.102-1.102c0.518,0,0.932,0.365,1.05,0.847l0.042-0.011l4.006,14.951l0.024-0.006
        		c0.509,1.293,1.762,2.212,3.236,2.212c1.925,0,3.485-1.561,3.485-3.485c0-0.176-0.027-0.344-0.052-0.513L72.147,54.101z"/>
        </svg>
    );
}

export default FemaleIcon;