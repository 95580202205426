import { createSlice } from '@reduxjs/toolkit';

import i18n from '../i18n';

export type AccountVO = {
    id: string
};

export type UserVO = {
    id: string,
    adult: boolean,
    dailyReward: boolean,
    email: string
};

export type SettingsVO = {
    id: string,
    accountID: string,
    language: string
}

export type CharacterVO = {
    id: string,
    accountID: string,
    bodyparts: string,
    bullets: number,
    hp: number,
    hunger: number,
    inteligence: number,
    dexterity: number,
    luck: number,
    strength: number,
    kills: number,
    message: string,
    nickname: string,
    quests: string,
    live: boolean,
    sex: string
}

export type ErrorVO = {
    message: string
}

export type StashVO = {
    accountID: string,
    bullets: number
}

const initialAccountState : {
    account : null | AccountVO,
    selectedCharacter : null | string,
    characters: null | string,
    stash: null | string,
    settings : {
        settingsID : string,
        language : string,
        music : number,
        sounds : number
    }
} = { 
    account : null,
    selectedCharacter : null,
    characters : null,
    stash : null,
    settings : {
        settingsID : "",
        language : i18n.language.slice(0, 2),
        music : 100,
        sounds : 100
    }
};

const accountSlice = createSlice({
    name: 'account',
    initialState: initialAccountState,
    reducers: {
        setAccount(state, action) {
            state.account = action.payload;
        },
        setSelectedCharacter(state, action) {
            state.selectedCharacter = action.payload;
        },
        setCharacters(state, action) {
            state.characters = action.payload;
        },
        setStash(state, action) {
            state.stash = action.payload;
        },
        setSettings(state, action) {
            state.settings = action.payload;
        }
    },
});

export const accountActions = accountSlice.actions;

export default accountSlice.reducer;