import React from 'react';

interface LogOutIconProps {
    className: string;
}

const LogOutIcon:React.FC<LogOutIconProps> = (props) => {
    return ( 
        <svg viewBox="0 0 24 24" 
                className={props.className}
                xmlns="http://www.w3.org/2000/svg">
            <path fill="#08CD91" d="M5 11h8v2H5v3l-5-4 5-4v3zm-1 7h2.708a8 8 0 1 0 0-12H4A9.985 9.985 0 0 1 12 2c5.523 0 10 4.477 10 10s-4.477 10-10 10a9.985 9.985 0 0 1-8-4z"/>
        </svg>

    );
}

export default LogOutIcon;