
import { createSlice } from '@reduxjs/toolkit';

const initialGameState : {
    gameLoading: boolean,
    gameProgression : number
} = { 
    gameLoading : true,
    gameProgression : 0
};

const gameSlice = createSlice({
  name: 'game',
  initialState: initialGameState,
  reducers: {
    setGameLoading(state, action) {
        state.gameLoading = action.payload;
    },
    setGameProgression(state, action) {
        state.gameProgression = action.payload;
    }
    /*
    call_ToLogin(state) {
        state.gameSendMessage("GameController", "ToLogin");
    },
    call_ToCharacters(state) {
        state.gameSendMessage("GameController", "ToCharacters");
    },
    call_ToHideaway(state) {
        state.gameSendMessage("GameController", "ToHideaway", "Home");
    },
    call_ToWorld(state) {
        state.gameSendMessage("GameController", "ToWorldPlay");
    },
    pauseGame(state) {
        state.gameSendMessage("GameController", "PauseGame");
    },
    resumeGame(state) {
        state.gameSendMessage("GameController", "ResumeGame");
    },
    call_Characters_SelectCharacter(state, action) {
        //action.payload.name;
        state.gameSendMessage("GameController", "CharactersSelectCharacter", action.payload.sex);
    },
    call_Characters_UnselectCharacter(state) {
        state.gameSendMessage("GameController", "CharactersUnselectCharacter");
    },
    call_Characters_SetInventory(state, action) {
        state.gameSendMessage("GameController", "CharactersSetInventory", action.payload);
    },
    call_Characters_SetStash(state, action) {
        state.gameSendMessage("GameController", "CharactersSetStash", action.payload);
    }*/
  },
});

export const gameActions = gameSlice.actions;

export default gameSlice.reducer;