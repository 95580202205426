import React from 'react';

interface ArrowLeftIconProps {
    className: string;
}

const ArrowLeftIcon:React.FC<ArrowLeftIconProps> = (props) => {
    return (    
        <svg viewBox="0 0 20 20" 
                className={props.className} 
                xmlns="http://www.w3.org/2000/svg">
           <path d="M2.5 10L9 3.5V7h8v6H9v3.5L2.5 10z"/>
        </svg>
    );
}

export default ArrowLeftIcon;