import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';

import { RootState } from './Slices/Store';

import { AppProperties } from './Properties';
import Footer from './Partials/Footer/Footer';
import Header from './Partials/Header/Header';
import ContentContainer from './Partials/ContentContainer';
import MainButton from './Components/Buttons/MainButton';
import Modals from './Partials/Modal/Modals';

interface GUIProps {
    gameSendMessage:Function;
}

const GUI:React.FC<GUIProps> = (props) => {

    const appState = useSelector((state : RootState) => state.gui.appState);
    
    const selectedCharacter = useSelector((state : RootState) => state.account.selectedCharacter);
    
    return(
        <div className="fixed flex flex-col w-full h-full justify-between cursor-brand-default">
            <Header 
                gameSendMessage={props.gameSendMessage} />

            <div className="w-full h-full basis-full relative z-0">
                { appState !== AppProperties.AppStates.WorldPlay &&
                    <Fragment>
                        <ContentContainer
                                gameSendMessage = { props.gameSendMessage } />

                        { ((appState === AppProperties.AppStates.Characters && selectedCharacter !== null)
                            || (appState === AppProperties.AppStates.Hideaway)
                            || (appState === AppProperties.AppStates.WorldPause)) &&
                            <MainButton 
                                    gameSendMessage={ props.gameSendMessage } />
                        }

                        <Modals />
                    </Fragment>
                }
            </div>

            <Footer />
        </div>
    );
}

export default GUI;