import React from 'react';

interface GiftIconProps {
    className: string;
}

const GiftIcon:React.FC<GiftIconProps> = (props) => {
    return (    
        <svg xmlns="http://www.w3.org/2000/svg"
                className={props.className}
                viewBox="0 0 199.516 199.516">
            <path d="M180.916,41.456h-15.858c3.637-2.664,6.443-6.369,8.01-10.704c2.289-6.329,1.69-13.313-1.641-19.161
                C167.354,4.441,159.706,0,151.466,0c-6.507,0-12.514,2.651-16.915,7.464l-19.558,21.391c-2.972-3.4-7.328-5.561-12.188-5.561H96.71
                c-4.86,0-9.216,2.161-12.188,5.562L64.964,7.465C60.563,2.651,54.557,0,48.05,0c-8.24,0-15.889,4.441-19.961,11.591
                c-3.332,5.849-3.93,12.833-1.641,19.162c1.567,4.335,4.373,8.039,8.009,10.703H18.6c-4.143,0-7.5,3.358-7.5,7.5v40.701
                c0,3.973,3.092,7.216,7,7.475v94.884c0,4.142,3.357,7.5,7.5,7.5h50.5h47.316h50.5c4.143,0,7.5-3.358,7.5-7.5V97.132
                c3.908-0.258,7-3.501,7-7.475V48.956C188.416,44.814,185.059,41.456,180.916,41.456z M115.916,56.529v127.987H83.6V56.529h4.408
                h23.5H115.916z M40.554,25.652c-0.804-2.225-0.603-4.582,0.568-6.637C42.555,16.501,45.145,15,48.05,15
                c2.244,0,4.319,0.918,5.844,2.586l21.825,23.87h-0.999L45.235,30.391C43.021,29.559,41.358,27.876,40.554,25.652z M158.962,25.651
                c-0.805,2.225-2.468,3.908-4.683,4.739l-29.483,11.066h-0.999l21.824-23.87c1.524-1.668,3.601-2.586,5.845-2.586
                c2.905,0,5.495,1.501,6.928,4.016C159.563,21.07,159.766,23.427,158.962,25.651z"/>
        </svg>
    );
}

export default GiftIcon;