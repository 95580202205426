import { AccountVO, ErrorVO } from 'ts/Slices/AccountSlice';
import db from '../Firebase';
import { collection, addDoc, getDocs, query, where } from 'firebase/firestore';


export const getAccountByEmail : (email: string) => Promise<ErrorVO | AccountVO> = async (email) => {
    return new Promise(async function(resolve, reject) {
        const collectionRef = collection(db, 'Account');
        const q = query(collectionRef, where('email', '==', email));
        getDocs(q)
        .then((snapshot) => {
            if (snapshot.empty) {
                resolve({ message:"No account founded."} as ErrorVO);
            }

            if(snapshot.size === 1) {
                resolve({ ...snapshot.docs[0].data(), id:snapshot.docs[0].id } as AccountVO);
            } else {
                resolve({message:"Unknown error during account loading."} as ErrorVO);
            }
        }).catch((error: string) => {
            resolve({ message: "Error during loading account." + error } as ErrorVO);
        });
    });
};

export const createAccount : (email: string, adult: boolean) => Promise<ErrorVO | string> = async (email, adult) => {
    return new Promise(async function(resolve, reject) {
        const collectionRef = collection(db, "Account");
        addDoc(collectionRef, {
            email: email,
            adult: adult,
            dailyReward: false
        })
        .then((docRef) => {
            resolve(docRef.id);
        })
        .catch((error: string) => {
            alert(error);
            resolve({ message: "Error during creating account." });
        });
    }); 
};    
/*
export const deleteAccount : (id: string) => Promise<Account> = (id) =>  {
        const db = firebase.firestore();
        db.collection("Account").doc(id).delete();
};

export const updateAccountReward = (id: any, reward: any) => {
        const db = firebase.firestore();
        db.collection("Account").doc(id).set({
                DailyReward: reward
        });
};

export const updateAccountPassword = (id: any, password: any) => {
        const db = firebase.firestore();
        db.collection("Account").doc(id).set({
                Password: password
        });
};
*/