import React from 'react';

interface NudityIconProps {
    className: string;
}

const NudityIcon:React.FC<NudityIconProps> = (props) => {
    return ( 
        <svg className={ props.className } viewBox="0 0 92 92" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M25.9683 50.9646C26.4432 51.6386 27.2056 52 27.9816 52C28.4617 52 28.9463 51.8614 29.3717 51.5728C29.4703 51.5057 29.5599 51.431 29.6449 51.3532C29.6495 50.3057 29.7249 49.5076 29.8795 48.9228C29.9111 48.6899 29.953 48.4659 30 48.2469C29.9962 48.2418 29.9936 48.2361 29.9904 48.2311C27.9551 45.3514 26.8898 41.7685 26.8931 38.2762C26.8912 35.1022 27.767 32.0484 29.4484 29.6269V27.3434H32.0571V24.7815H34.6654V27.3434H37.2741V24.7815H39.8824V27.3434H42.4911V24.7815H45.0998V27.3434H47.7081V24.7815H50.3168V27.3434H52.925V24.7815H51.4144C51.5639 24.379 51.6922 24.041 51.7927 23.7802C55.3528 23.5049 58.5061 23.0094 61.4024 22.1308C66.6813 20.5448 70.9806 17.5437 74.7436 12.7671C75.6864 13.7582 76.3818 14.879 76.483 16.0657C75.0052 16.4517 73.6583 18.0884 73.2123 20.2478C72.6839 22.8034 73.6042 25.1699 75.2849 25.7427C75.2153 26.4768 75.1573 27.2268 75.1173 27.9875C74.9781 27.9831 74.8376 27.9862 74.6978 28.0065C72.8037 28.2748 71.5934 30.709 71.9942 33.4438C72.3918 36.1564 74.2247 38.1438 76.1026 37.9147C76.9185 40.4254 78.2944 42.6482 80.5257 44.219L81 43.5671C78.9512 42.1367 77.6597 40.0653 76.8825 37.6811C78.3416 36.9691 79.198 34.835 78.8514 32.4742C78.5382 30.34 77.3363 28.6584 75.9236 28.1477C75.9623 27.3756 76.0209 26.6142 76.0905 25.8687C77.8055 25.8548 79.4913 24.0699 80.0018 21.6016C80.5612 18.8934 79.4959 16.3947 77.6204 16.0214C77.5089 15.9993 77.3968 15.986 77.2853 15.9796C77.1364 14.5012 76.3109 13.2094 75.2443 12.1151C75.9712 11.0607 75.7373 9.62203 74.6894 8.84999C73.6466 8.08291 72.1856 8.25823 71.3562 9.2234C69.979 8.44051 68.9053 8.00507 68.8936 8L68.5837 8.73986C68.6081 8.74999 69.0934 8.94809 69.8288 9.31644C70.1355 9.46643 70.4816 9.64745 70.8496 9.8544C67.5293 14.0095 64.3199 16.1859 59.9509 17.5435C55.3945 18.9448 49.4241 19.3036 41.1872 19.2973V19.2966C34.9422 19.2757 29.9645 21.6105 26.744 25.225C23.5119 28.8301 22.002 33.5477 22 38.2755C22.0039 42.7083 23.3224 47.2051 25.9674 50.9645L25.9683 50.9646Z" fill="black"/>
            <path d="M43.4275 0.223299C47.9986 1.28832 50.841 5.85626 49.7767 10.4273C48.7123 14.9984 44.1436 17.8415 39.5725 16.7765C35.0014 15.7121 32.159 11.1435 33.2233 6.57245C34.2877 2.00188 38.8563 -0.841064 43.4275 0.223299Z" fill="black"/>
            <path d="M38.4057 30H40V27H38V30H38.4057Z" fill="black"/>
            <path d="M37 48H40V51H37V48Z" fill="black"/>
            <path d="M40 51H43V54H40V51Z" fill="black"/>
            <path d="M43 48H46V51H43V48Z" fill="black"/>
            <path d="M37 54H40V57H37V54Z" fill="black"/>
            <path d="M43 54H46V57H43V54Z" fill="black"/>
            <path d="M30 30H33V32H30V30Z" fill="black"/>
            <path d="M43 32V30H40V32H43Z" fill="black"/>
            <path d="M33 27H35V30H33V27Z" fill="black"/>
            <path d="M35 30H38V32H35V30Z" fill="black"/>
            <path d="M33 32H35V35H33V32Z" fill="black"/>
            <path d="M38 32H40V35H38V32Z" fill="black"/>
            <path d="M30 35H33V38H30V35Z" fill="black"/>
            <path d="M43 27H46V30H43V27Z" fill="black"/>
            <path d="M43 32H46V35H43V32Z" fill="black"/>
            <path d="M46 30H48V32H46V30Z" fill="black"/>
            <path d="M48 27H51V30H48V27Z" fill="black"/>
            <path d="M48 32H51V35H48V32Z" fill="black"/>
            <path d="M47.8838 35H45.3307V37.5985H42.7771V35H40.2235V37.5985H37.6704V35H35.1169V37.5985H34.826C34.9049 38.1814 34.9327 38.77 34.8929 39.3561C34.8916 39.3766 34.8916 39.3978 34.8904 39.4183C34.5718 43.6262 31.7197 45.3075 31.2176 49.2496C31.0789 49.7657 31 50.5367 31 51.6473C31 58.8556 32.7461 59.6079 32.8559 66.2874V87.9404C32.8559 90.1828 34.6419 92 36.8453 92C39.0483 92 40.8348 90.1826 40.8348 87.9404V59.8175H42.1652V87.9404C42.1652 90.1828 43.9512 92 46.1547 92C48.357 92 50.1441 90.1826 50.1441 87.9404V66.2874C50.2539 59.6086 52 58.8556 52 51.6473C52 50.5374 51.9211 49.7671 51.7824 49.2503C51.2815 45.3069 48.4287 43.6262 48.1109 39.4183C48.109 39.3978 48.109 39.376 48.1084 39.3561C48.0724 38.8278 48.0869 38.2346 48.1399 37.5985L47.8838 37.5978L47.8838 35ZM42.977 59.8181V56.8139H40.0247V59.8181H37.0731V56.8139H34.1208V53.8103H37.0731V50.8061H34.1208V47.8018H37.0731V44.7976H40.0247V47.8018H42.977V44.7976H45.9287V47.8018H48.881V50.8061H45.9287V53.8103H48.881V56.8139H45.9287V59.8181H42.977Z" fill="black"/>
            <path d="M51 30H53V32H51V30Z" fill="black"/>
            <path d="M51 35H53V38H51V35Z" fill="black"/>
        </svg>
    );
}

export default NudityIcon;