import React from 'react';

import { useTranslation } from 'react-i18next';

import LeftBarWindow from 'ts/Components/Windows/LeftBarWindow';

const Ladder:React.FC = () => {

    const { t } = useTranslation();
        
    return (
        <LeftBarWindow title = { t('ladder.title') }>        
        </LeftBarWindow>
    );
}

export default Ladder;