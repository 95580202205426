import React from 'react';
import Button from 'ts/Components/Buttons/Button';
import GiftIcon from 'ts/Icons/Forms/GiftIcon';

interface ClaimRewardButtonProps {
    handleClick: Function;
}


const ClaimRewardButton: React.FC<ClaimRewardButtonProps> = (props) => {
    return (
        <Button handleClick = { props.handleClick }
            additionalClassName={ "cursor-brand-help p-4"}
        >
            <GiftIcon className={'h-24'} />
        </Button>
    );
};

export default ClaimRewardButton;