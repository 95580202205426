import { createSlice } from '@reduxjs/toolkit';

import { AppProperties, GUIProperties } from '../Properties';

const initialGuiState: {
    appState : number;
    windowId : number;
    loading : boolean;
    confirmBox : boolean;
    confirmCallback: Function;
    confirmLabel : string;
} = {
    appState: AppProperties.AppStates.Login,
    windowId: GUIProperties.Windows.Id.Splash,
    loading: false,
    confirmBox: false,
    confirmCallback: () => {},
    confirmLabel: ''
};

const guiSlice = createSlice({
    name: 'gui',
    initialState: initialGuiState,
    reducers: {
        setAppState(state, action) {
            state.appState = action.payload;
        },
        setWindowId(state, action) {
            state.windowId = action.payload;
        },
        setLoading(state, action) {
            state.loading = action.payload;
        },
        handleWindowClose(state) {
            state.windowId = GUIProperties.Windows.Id.None;
        },
        handleFullscreenChange() {
            const isInFullScreen = document.fullscreenElement; /* Standard syntax */
                    //|| document.webkitFullscreenElement /* Chrome, Safari and Opera syntax */
                    //|| document.mozFullScreenElement /* Firefox syntax */
                    //|| document.msFullscreenElement;
    
            if (isInFullScreen) {
                const cancelRequestMethod = document.exitFullscreen;
                        //|| document.msExitFullscreen 
                        //|| document.webkitExitFullscreen 
                        //|| document.mozCancelFullScreen;
                        
        
                if (cancelRequestMethod) { 
                    cancelRequestMethod.call(document);
                } 
            } else {
                const requestMethod = document.body.requestFullscreen ;
                        //|| document.body.webkitRequestFullScreen 
                        //|| document.body.mozRequestFullScreen 
                        //|| document.body.msRequestFullscreen;

                if (requestMethod) { 
                    requestMethod.call(document.body);
                } 
            }
        },
        setConfirmBox(state, action) {
            state.confirmBox = action.payload;
        },
        setConfirmCallback(state, action) {
            state.confirmCallback = action.payload;
        },
        confirmLabel(state, action) {
            state.confirmLabel = action.payload;
        },
        showConfirmBox(state, action) {
            state.confirmCallback = action.payload.confirmCallback; 
            state.confirmLabel = action.payload.confirmLabel;
            state.confirmBox = true;
        },

        hideConfirmBox(state) {
            state.confirmBox = false; 
        }
    },
});


export const guiActions = guiSlice.actions;

export default guiSlice.reducer;