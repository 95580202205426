import React from 'react';

interface MaleIconProps {
    className: string;
}

const MaleIcon:React.FC<MaleIconProps> = (props) => {
    return (    
        <svg xmlns="http://www.w3.org/2000/svg"
				className={props.className}
    	    	viewBox="0 0 100 100">
        	<circle cx="49.354" cy="19.938" r="8.272"/>
	        <path d="M65.016,30.435v-0.04H35.38v0.027c-2.142,0-3.878,1.729-3.894,3.867h-0.003v23.439h0.039
		            c-0.002,0.893,0.337,1.786,1.018,2.467c1.358,1.359,3.56,1.359,4.919,0c0.68-0.681,1.019-1.574,1.017-2.467h0.008V41.537v-0.172
		            h0.009c0.025-0.592,0.505-1.064,1.101-1.064c0.598,0,1.078,0.473,1.103,1.064h0.009v0.172v10.44v32.293
		            c0.003,2.245,1.822,4.063,4.068,4.063c2.246,0,4.068-1.821,4.068-4.068V62.661h0.03c0-0.623,0.504-1.128,1.126-1.128
		            c0.622,0,1.127,0.505,1.127,1.128h0.029v21.604c0,2.247,1.823,4.068,4.069,4.068c2.247,0,4.068-1.821,4.068-4.068V62.661V51.978
		            v-10.44v-0.172c0-0.615,0.497-1.112,1.112-1.112c0.613,0,1.11,0.497,1.11,1.112v0.172v16.191h0.038
		            c-0.002,0.893,0.337,1.786,1.018,2.467c1.359,1.359,3.561,1.359,4.92,0c0.681-0.681,1.02-1.574,1.018-2.467h0.007V34.289
		            C68.516,32.274,66.979,30.636,65.016,30.435z"/>
        </svg>

    );
}

export default MaleIcon;