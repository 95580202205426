import React from "react";

import CloseButton from "ts/Partials/Buttons/CloseButton";

import { Fade } from "react-awesome-reveal";

interface LeftBarWindowProps {
    children?: React.ReactNode;
    handleCloseClick?: Function;
    title?: String;
}

const LeftBarWindow: React.FC<LeftBarWindowProps> = (props) => {

    return (
        <Fade className="w-full h-full basis-full z-20 relative">
            <div className="flex items-center h-full py-1">
                <div className="flex flex-col max-w-xl basis-full w-full bg-scratches2 bg-center bg-cover bg-opacity-60 hover:bg-opacity-80 bg-brand-gray-500 border-2 border-brand-horror-teal-300 outline outline-2 outline-offset-2 hover:outline-offset-2 outline-brand-horror-cyan-300 hover:outline-brand-horror-cyan-500 py-8 px-12 h-full mr-auto rounded-3xl justify-start items-center">
                    <div className =  {"w-full flex justify-between items-center"}>
                        <div className="w-8"></div>
                        <h3 className = { "text-center font-bold text-5xl text-brand-blood-700 mb-2" }>
                            { props.title }
                        </h3>
                        <div>
                            <CloseButton 
                                    handleCloseClick = { props.handleCloseClick } />
                        </div>
                    </div>
                    <div className =  {"w-1/2 h-full flex flex-col justify-between items-center mt-4"}>
                        { props.children }
                    </div>
                </div>
            </div>
        </Fade>
    );
}

export default LeftBarWindow;