import React from 'react';

interface LadderIconProps {
    className: string;
}

const LadderIcon:React.FC<LadderIconProps> = (props) => {
    return ( 
        <svg className={props.className} viewBox="0 0 92 92" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M29.7326 42.0812L42.7557 37.4507L55.7789 42.0812L55.3996 28.2645L63.8278 17.3099L50.5701 13.4017L42.7557 2L34.9421 13.4017L21.6836 17.3099L30.1126 28.2645L29.7326 42.0812Z" fill="black"/>
            <path d="M46.4214 86.2122V45.4673C46.4214 43.4429 44.7807 41.803 42.7571 41.803C40.7327 41.803 39.0928 43.4437 39.0928 45.4673V86.2122C39.0928 88.2366 40.7335 89.8765 42.7571 89.8765C44.7807 89.8765 46.4214 88.2372 46.4214 86.2129V86.2122Z" fill="black"/>
            <path d="M75.3473 27.5144L69.1708 36.5252L58.6934 39.6134L65.3554 48.2713L65.0545 59.1909L75.3474 55.53L85.6396 59.1909L85.3401 48.2713L92.0006 39.6134L81.5232 36.5252L75.3473 27.5144Z" fill="black"/>
            <path d="M75.3462 87.4736C77.3706 87.4736 79.0105 85.8329 79.0105 83.8093L79.0113 65.0532C79.0113 63.0289 77.3705 61.3889 75.3469 61.3889C73.3226 61.3889 71.6826 63.0296 71.6826 65.0532V83.8075C71.6819 85.8333 73.3226 87.4732 75.3462 87.4732L75.3462 87.4736Z" fill="black"/>
            <path d="M4.76367 62.409L12.4711 59.6687L20.1785 62.409L19.9539 54.2324L24.9423 47.748L17.096 45.4363L12.4712 38.688L7.84633 45.4363L0 47.748L4.98847 54.2324L4.76367 62.409Z" fill="black"/>
            <path d="M12.471 87.4735C14.4953 87.4735 16.1353 85.8328 16.1353 83.8092V70.918C16.1353 68.8936 14.4946 67.2537 12.471 67.2537C10.4466 67.2537 8.80664 68.8944 8.80664 70.918V83.8092C8.80593 85.8336 10.4459 87.4735 12.471 87.4735Z" fill="black"/>
        </svg>

    );
}

export default LadderIcon;