import React, { useState, useEffect, useCallback } from 'react';

import { GUIProperties } from '../../Properties';

import { guiActions } from '../../Slices/GuiSlice';

import { useDispatch } from 'react-redux';

const Splash:React.FC = () => {

    const [seconds, setSeconds] = useState(3);

    const dispatch = useDispatch();

    const handleSplash = useCallback(() => {
        dispatch(guiActions.setWindowId(GUIProperties.Windows.Id.Login));
    }, [dispatch]);

    useEffect(() => {

        let splashInterval: NodeJS.Timer;
        splashInterval = setInterval(() => {
            if (seconds > 0) {
                setSeconds(seconds - 1);
            }
            if (seconds === 0) {
                clearInterval(splashInterval);
                handleSplash();
            } 

        }, 1000);

        return () => {
            clearInterval(splashInterval);

        }
    }, [seconds, handleSplash]);


    return (
        <noscript />
    );
}

export default Splash;
