import React from "react";

import CloseButton from "ts/Partials/Buttons/CloseButton";

import { Fade } from "react-awesome-reveal";

interface BigWindowProps {
    children?: React.ReactNode;
    handleCloseClick?: Function;
    title?: String;
}

const BigWindow: React.FC<BigWindowProps> = (props) => {

    return (
        <Fade className = "w-full h-full relative z-20">
            <div className = "flex justify-center items-center h-full">
                <div className = "flex flex-col text-l max-w-3xl w-full bg-brand-gray-700 bg-scratches3 bg-opacity-60 hover:bg-opacity-80 bg-no-repeat bg-center border-2 border-brand-horror-teal-300 outline outline-2 outline-offset-2 hover:outline-offset-2 outline-brand-horror-cyan-300 hover:outline-brand-horror-cyan-500 p-8 h-[600px] mx-auto rounded-3xl items-center z-10">
                    <div className = { "w-full flex mb-4" }>
                        <div className = "basis-2/12"></div>
                        <h3 className = { "basis-8/12 text-4xl font-bold text-center text-brand-blood-500 mb-1" }>
                            { props.title }
                        </h3>
                        <div className = "basis-2/12">
                            <CloseButton 
                                    handleCloseClick = { props.handleCloseClick } />
                        </div>
                    </div>
                    { props.children }
                </div>
            </div>
        </Fade>
    );
}

export default BigWindow;