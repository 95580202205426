import React from 'react';

//import postscribe from 'postscribe';

const AdvertIframe: React.FC = () => {

        
    //useEffect(() => {
    //    componentDidMount();
    //}, []);
    //componentDidMount = () => {
    //    postscribe('#AdvertContainer', '<script src="https://www.camiocw.com/script?providers=mfc&genders=f%2Cff&skin=3&containerAlignment=center&iframeHeight=400px&iframeWidth=100%&cols=1&rows=1&number=1&background=transparent&useFeed=1&animateFeed=1&smoothAnimation=1&ratio=1&verticalSpace=10px&horizontalSpace=10px&colorFilter=0&colorFilterStrength=0&cardsBorderTop=1px&cardsBorderRight=1px&cardsBorderBottom=1px&cardsBorderLeft=1px&cardsBorderColor=%23000000&cardsBorderRadius=6px&AuxiliaryCSS=%0A&token=1d637000-d4d5-11ea-bea3-7f29f8a4338e"></script>');
    //}

    return (
        <div id="AdvertContainer"><span className="py-4">REKLAMA</span></div>
    );
};

export default AdvertIframe;