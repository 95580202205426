import { configureStore, EnhancedStore } from '@reduxjs/toolkit';

import gameReducer from './GameSlice';
import guiReducer from './GuiSlice';
import accountReducer from './AccountSlice';

const store: EnhancedStore = configureStore({
    reducer: { 
        game: gameReducer, 
        gui: guiReducer,
        account: accountReducer
    },
    middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
        serializableCheck: false,
    }),
});

export type RootState = ReturnType<typeof store.getState>

export default store;
