import { ErrorVO, StashVO } from 'ts/Slices/AccountSlice';
import db from '../Firebase'
import { collection, doc, setDoc, addDoc, getDocs, deleteDoc, query, where } from 'firebase/firestore';

// Stash
export const getStashByAccountId: (accountID: string) => Promise<ErrorVO | StashVO> = async (accountID: string) => {
    return new Promise(function(resolve, reject) {

        const collectionRef = collection(db, 'Stash');
        const q = query(collectionRef, where('accountID', '==', accountID));
        getDocs(q).then((snapshot) => {
            if (snapshot.empty) {
                resolve({message : "Stash not found."});
            }

            if(snapshot.size === 1) {
                resolve(snapshot.docs[0].data() as StashVO);
            } else {
                resolve({message : "This account is having invalid records in db."});
            }
        }).catch((error: string) =>  {
            resolve({ message: "Error during loading settings for account. " + error});
        });
    });
};

export const createStash: (accountID: string) => Promise<ErrorVO | string> = async(accountID) => {
    return new Promise(function(resolve, reject) {   
        const collectionRef = collection(db, "Stash");
        addDoc(collectionRef, {
            accountID:accountID,
            bullets:0
        })
        .then((docRef) => {
            resolve(docRef.id);
        })
        .catch((error: string) => {
            resolve({ message: "Error during creating stash. " + error });
        });
    });
};

export const deleteStash: (id: string) => Promise<ErrorVO | boolean> = async(id) => {
    return new Promise(function(resolve, reject) {      
        const collectionRef = collection(db, "Stash");
        deleteDoc(doc(collectionRef, id))
        .then((): void => {
            resolve(true);
        })
        .catch((error: string) => {
            resolve({ message: "Error during deleting settings. " + error});
        });
    });
};

export const updateStash: (id: string, bullets: number) => Promise<ErrorVO | boolean> = async(id, bullets) => {
    return new Promise(function(resolve, reject) {        
        const collectionRef = collection(db, "Stash");
        setDoc(doc(collectionRef, id), {
                bullets : bullets
        })
        .then(() => {
            resolve(true);
        })
        .catch((error: string) => {
            resolve({ message: "Error during deleting settings. " + error});
        });
    });
};
