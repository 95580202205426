import React, { useState, useRef } from 'react';

import { GUIProperties } from 'ts/Properties';

import { register } from 'ts/Services/AuthService';
import { createAccount } from 'ts/Services/AccountService';
import { createSettings } from 'ts/Services/SettingsService';
import { createStash } from 'ts/Services/StashService';

import { guiActions } from 'ts/Slices/GuiSlice';

import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { RootState } from 'ts/Slices/Store';
import { ErrorVO } from 'ts/Slices/AccountSlice';
import BigWindow from 'ts/Components/Windows/BigWindow';
import CheckboxIcon from 'ts/Icons/Forms/CheckboxIcon';
import Button from 'ts/Components/Buttons/Button';
import AdultIcon from 'ts/Icons/Forms/AdultIcon';
import NudityIcon from 'ts/Icons/Forms/NudityIcon';
import GoreIcon from 'ts/Icons/Forms/GoreIcon';
import WarningIcon from 'ts/Icons/Forms/WarningIcon';
import RegistrationIcon from 'ts/Icons/Forms/RegistrationIcon';
import IconButton from 'ts/Components/Buttons/IconButton';

const Registration: React.FC<{}> = (props) => {

    const dispatch = useDispatch();
    
    const { t } = useTranslation("translations");

    const settings  = useSelector((state: RootState) => state.account.settings);

    const [errorMessage, setErrorMessage] = useState("");

    const [checked, setChecked] = useState(false);
    const [success, setSuccess] = useState(false);

    const emailInputRef = useRef<HTMLInputElement>(null);
    const password1InputRef = useRef<HTMLInputElement>(null);
    const password2InputRef = useRef<HTMLInputElement>(null);



    const setCheckAdultConfirmAction = () => {
        dispatch(guiActions.showConfirmBox(
            { 
                confirmCallback : setConfirmAdultConfirmAction, 
                confirmLabel : t('registration.adultConfirmMessage') 
            }
        ));
    }

    const setConfirmAdultConfirmAction = () => {
        dispatch(guiActions.hideConfirmBox());

        setChecked(true);
    }

    const handleCheckboxChange = () => {
        if(!checked) {
            dispatch(guiActions.showConfirmBox(
                { 
                    confirmCallback : setCheckAdultConfirmAction, 
                    confirmLabel : t('registration.adultCheckMessage') 
                }
            ));
        } else {
            setChecked(false);
        }
    };

    const validateEmail = (email : string | undefined) => {
        if(email) {
            const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(email);
        } else {
            return false;
        }
    };

    const handleRegistrationClick = async() => {      
        dispatch(guiActions.setLoading(true));

        const email = emailInputRef.current?.value;
        const password1 = password1InputRef.current?.value;
        const password2 = password2InputRef.current?.value;

        if(email && password1 && password2) {
            let valid = true;
            if(!validateEmail(email)) {
                setErrorMessage(t('error.registration.emailNotValid')); 

                valid = false;
            }
            if(!(password1.length > 5)) {
                setErrorMessage(t('error.registration.passwordLength')); 

                valid = false;
            }
            if(password1 !== password2) {
                setErrorMessage(t('error.registration.passwordsNotEqual')); 

                valid = false;
            }
            if(checked === false) {
                setErrorMessage(t('error.registration.notAdult')); 

                valid = false;
            }
            if (valid === true) {
                let response = await register(email, password1);
                if(response === true) {
                    const createAccountResult : string | ErrorVO = await createAccount(email, true);
                    if(typeof createAccountResult === 'string') {
                        const accountId : string = createAccountResult as string;
                        await createStash(accountId);
                        await createSettings(accountId, settings.language);
                        setSuccess(true);
                    } else if(createAccountResult as ErrorVO) {
                        setErrorMessage(createAccountResult.message); 
                    }
                } 
            }
        } else {
            setErrorMessage(t('error.registration.fillAllFields')); 
        }
        
        dispatch(guiActions.setLoading(false));
    };

    const handleCloseRegistrationClick = () => {
        dispatch(guiActions.setWindowId(GUIProperties.Windows.Id.Login));
    };
    
    if(success === false) {
        return (
            <BigWindow handleCloseClick={ handleCloseRegistrationClick }
                    title={ t('registration.title') }>
                <div className = { "w-full h-full flex flex-col justify-between items-center" }>
                    <div className = { "w-full flex flex-col items-center" }>
                        <div className = { "w-full my-1 text-center h-8" } >
                            <span className = { "text-brand-blood-500" }>
                                { errorMessage }
                            </span>
                        </div>
                        <div className="flex flex-col items-center w-6/12 my-1">
                            <input 
                                    placeholder ={ t('app.email') } 
                                    type = "text"
                                    ref = { emailInputRef } 
                                    className="my-4 w-full rounded-xl px-4 py-2 text-brand-black cursor-brand-text" />
                            <input 
                                    placeholder = { t('app.password') } 
                                    type = "password" 
                                    ref= { password1InputRef } 
                                    className="my-4 w-full rounded-xl px-4 py-2 text-brand-black cursor-brand-text" />
                            <input 
                                    placeholder = { t('registration.repeatPassword') } 
                                    type = "password" 
                                    ref = { password2InputRef } 
                                    className="my-4 w-full rounded-xl px-4 py-2 text-brand-black cursor-brand-text" />
                        </div>
                        <div  className = {"flex my-1 flex flex-col w-10/12 items-center" }>
                            
                            <div className = "w-full my-2 flex justify-center items-center" >
                                
                                <span className = { "text-brand-blood-900 text-2xl mr-1" }>
                                    { t('registration.warning') }
                                </span>
                                
                                <span className = { "text-brand-horror-turqoise-900 text-2xl ml-1" }>
                                    { t('registration.warningMessage') }
                                </span>
                            </div>
                            <div className = "w-1/2 my-2 flex justify-between items-center" >
                                <WarningIcon className="h-8 mx-1" />
                                <div className = "flex">
                                    <AdultIcon className="h-8 mx-1" /> 
                                    <NudityIcon className="h-8 mx-1" />
                                    <GoreIcon className="h-8 mx-1" />
                                </div>
                                <WarningIcon className="h-8 mx-1" />
                            </div>
                            <div className = "w-full my-2 flex justify-center items-center" >
                                    <span className = "text-brand-horror-turqoise-900 h-8 text-2xl">
                                        { t('registration.adultCheckMessage') }
                                    </span>
                                    <button
                                        className = "ml-2 cursor-brand-link"
                                        onClick = { handleCheckboxChange } 
                                    >
                                        <CheckboxIcon checked={checked} className={'h-8 fill-brand-horror-teal-100'} />
                                    </button>
                            </div>
                        </div>
                    </div>
                    <div className="w-1/2">
                        <IconButton text = { t('registration.register') }
                                handleClick = { handleRegistrationClick }
                                icon = { <RegistrationIcon className='h-8 fill-current' /> } />
                    </div>
                </div>
            </BigWindow>
        );
    } else {
        return (
            <BigWindow handleCloseClick={ handleCloseRegistrationClick }
                    title={ t('registration.title') }
            >
                <div className="w-full text-center my-1">
                    <h2 className = { "text-green-500 text-4xl" }>
                        { t('registration.registrationSuccess') }
                    </h2>
                </div>
                <Button additionalClassName = { "mt-1 p-1" }
                        handleClick = { handleCloseRegistrationClick }>
                    <span>{ t('app.ok') }</span>
                </Button>
            </BigWindow>
        );
    }
}

export default Registration;
