import React from 'react';

interface RemoveIconProps {
    className: string;
}

const RemoveIcon:React.FC<RemoveIconProps> = (props) => {
    return (    
		<svg className={props.className} viewBox="0 0 92 92" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M58.0461 5.59098C58.0321 5.56312 58.0321 5.53527 58.0321 5.50724C58.0321 5.47921 58.0181 5.43734 58.0181 5.3953C57.6408 2.62775 55.4462 0.433406 52.6787 0.0558828C52.6088 0.0418672 52.5389 0.0418672 52.483 0.0278516C52.3154 0.0140156 52.1337 0 51.9519 0H39.3581C36.1573 0 33.5296 2.41823 33.1661 5.50724C33.1661 5.53527 33.1661 5.56312 33.1521 5.59098C33.138 5.77264 33.124 5.95448 33.124 6.15016V14.1592H58.074V6.15016C58.074 5.95448 58.0602 5.77264 58.0461 5.59098ZM39.3581 4.9202H51.8401C52.5529 4.9202 53.154 5.50724 53.154 6.23426V9.23935H38.0442V6.23408C38.0442 5.50724 38.6313 4.9202 39.3581 4.9202Z" fill="#E3E2E2"/>
			<g opacity="0.06">
				<path d="M58.0461 5.59106V5.50732H58.0321C56.5086 5.77272 54.9013 5.96858 53.2519 6.12221C53.2239 6.13623 53.182 6.13623 53.154 6.13623C53.154 6.13623 53.154 6.13623 53.154 6.15025V9.15534H38.0442V6.15025C38.0442 6.13623 38.0442 6.13623 38.0442 6.13623C36.3528 5.9826 34.7315 5.7729 33.1661 5.50732H33.1521V5.59106C33.138 5.77272 33.124 5.95457 33.124 6.15025V14.0754H58.074V6.15025C58.074 5.95457 58.0602 5.77272 58.0461 5.59106Z" fill="#040000"/>
			</g>
			<path d="M80.0608 26.5154L80.0328 27.4099L77.9642 80.5384C77.9642 86.6744 76.0074 91.6785 71.213 91.9863C71.1012 92 70.9754 92 70.8497 92H20.4466C19.9293 92 19.4402 91.9441 18.9789 91.8463C16.6448 91.329 15.0932 89.5539 14.1987 87.0661C13.5417 85.2071 13.2343 82.9565 13.2343 80.5384L11.1377 26.5154H80.0608Z" fill="#E9E8E8"/>
			<path d="M81.556 23.9576H9.6416C9.6416 23.2028 9.69766 22.4619 9.82327 21.7351C9.96306 20.8546 10.2006 20.0022 10.5082 19.1914C10.5921 18.9815 10.6759 18.772 10.7738 18.5621C11.4448 17.0109 12.4093 15.6131 13.5834 14.4248C15.5262 12.4821 18.0562 11.1263 20.8935 10.6649C21.6063 10.5393 22.3471 10.4834 23.102 10.4834H68.3053C68.5428 10.4834 68.7805 10.4971 69.0181 10.5114C69.1717 10.5251 69.3115 10.5395 69.4513 10.5531C74.3853 11.0564 78.5226 14.2013 80.4236 18.5621C80.5215 18.772 80.6052 18.9815 80.6751 19.1914C81.2485 20.6731 81.556 22.2802 81.556 23.9576Z" fill="#E9E8E8"/>
			<path d="M84.1839 18.562H7V24.1954H84.1839V18.562Z" fill="#F2F2F2"/>
			<path d="M84.1839 18.562H7V19.1913H84.1839V18.562Z" fill="#FDFEFE"/>
			<path d="M24.3575 35.6517L29.8069 35.4796C30.7355 35.4503 31.5085 36.2231 31.5271 37.1997L32.3518 80.8144C32.37 81.7769 31.6474 82.5753 30.7319 82.6042L25.2826 82.7764C24.3537 82.8057 23.5809 82.0328 23.5624 81.0562L22.7376 37.4416C22.7195 36.479 23.442 35.6806 24.3575 35.6517Z" fill="#5D5D5D"/>
			<path d="M66.8345 35.6517L61.3851 35.4796C60.4563 35.4503 59.6835 36.2231 59.6649 37.1997L58.8402 80.8144C58.822 81.7769 59.5446 82.5753 60.4601 82.6042L65.9094 82.7764C66.8382 82.8057 67.6111 82.0328 67.6296 81.0562L68.4544 37.4416C68.4725 36.479 67.75 35.6806 66.8345 35.6517Z" fill="#5D5D5D"/>
			<path d="M48.4138 35.5581H42.8954C41.9916 35.5581 41.2588 36.2909 41.2588 37.1945V81.0613C41.2588 81.9649 41.9916 82.6977 42.8954 82.6977H48.4138C49.3176 82.6977 50.0504 81.9649 50.0504 81.0613V37.1945C50.0504 36.2909 49.3176 35.5581 48.4138 35.5581Z" fill="#5D5D5D"/>
			<g opacity="0.1">
				<path d="M80.1724 26.5154L80.1303 27.5078L78.0756 80.5384C78.0756 86.7168 76.1048 91.7484 71.2126 91.9863C71.101 92 70.9753 92 70.8495 92H45.4941V26.5154H80.1724Z" fill="#040000"/>
				<path d="M84.3099 18.5621V24.1955H45.4941V9.15526H53.2656V6.23408C53.2656 6.19203 53.2656 6.16418 53.2516 6.12213C53.2097 5.45118 52.6365 4.92002 51.9518 4.92002H45.4941V0H51.9518C52.1334 0 52.3151 0.0140156 52.4829 0.0280313C52.5528 0.0280313 52.6087 0.0280312 52.6646 0.0420469C52.6786 0.0420469 52.6786 0.0560625 52.6786 0.0560625C55.53 0.377523 57.7664 2.65578 58.1158 5.49341V5.50742H58.1298C58.1438 5.50742 58.1438 5.50742 58.1578 5.50742C58.1578 5.52144 58.1578 5.52144 58.1578 5.53545C58.1718 5.73095 58.1858 5.94065 58.1858 6.15034V6.22024C58.1858 6.23426 58.1858 6.23426 58.1858 6.23426V10.4835H68.3055C68.543 10.4835 68.7807 10.4972 69.0183 10.5115C69.1719 10.5252 69.3117 10.5396 69.4515 10.5532C74.4276 11.0006 78.6208 14.1736 80.5357 18.5623L84.3099 18.5621Z" fill="#040000"/>
			</g>
		</svg>

    );
}

export default RemoveIcon;