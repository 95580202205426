import React from "react";

interface ButtonProps {
    children?: React.ReactNode;
    handleClick?: Function;
    icon?: String;
    additionalClassName?: String;
}

const HeaderButton: React.FC<ButtonProps> = (props) => {

    const handleClick = () => {
        if(props.handleClick) {
            props.handleClick();
        } 
    };

    return (
        <button className = { "text-brand-horror-teal-900 border-2 border-brand-horror-teal-300 bg-opacity-40 hover:bg-opacity-80 hover:border-brand-horror-teal-500 text-2xl hover:font-semibold bg-brand-horror-cyan-100 rounded tracking-widest transition-shadow ease-in-out duration-600 cursor-brand-link " + props.additionalClassName }
                onClick = { handleClick }>
            { props.children }
        </button>                
    );
}

export default HeaderButton;