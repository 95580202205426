export const AppProperties = {
    version : "1.0.6",
    AppStates :{
        Login : 0,
        Characters : 1,
        Hideaway : 2,
        WorldPlay : 3,
        WorldPause : 4
    }
};

export const GUIProperties = {
    Windows : {
        Id : {
            Initializing : -5,
            Splash : -4,
            Registration : -3,
            Login : -1,
            None : 0,
            Game : 1,
            Characters : 2,
            Ladder : 3,
            Character : 4,
            Stash : 5,
            World : 6,
            Hideaway : 7,
            Bulletshop : 8,
            Profile : 9,
            Settings : 10,
            Airdrop : 11
        }
    },
    Modals : {
        Id : {
            Nothing : 0,
            Loading : 1,
            ConfirmBox : 2
        }
    },
    Languages : {
        Czech : "cs",
        English : "en"
    },
};

export const CharacterProperties = {
    Sex : {
        Male : "M",
        Female : "F"
    },
    Modes : {
        Selection : 0,
        New : 1,
        Detail : 2,
        Dead : 3
    }
}