import { CharacterVO, ErrorVO } from 'ts/Slices/AccountSlice';
import db from '../Firebase';
import { collection, doc, setDoc, addDoc, getDocs, deleteDoc , query, where } from 'firebase/firestore';

// Character
export const getCharactersByAccountId: (accountID: string) => Promise<ErrorVO | CharacterVO[]> = async (accountID) => {
    return new Promise(function(resolve, reject) {
        const collectionRef = collection(db, 'Character');
        const q = query(collectionRef, where('accountID', '==', accountID));
        
        getDocs(q).then((snapshot) => {
            if (snapshot.empty) {
                resolve([]);
            }
    
            let characters : CharacterVO[] = [];
            snapshot.forEach(doc => {
                characters.push({ ...doc.data(), id:doc.id } as CharacterVO);
            });
            resolve(characters);
        }).catch((error: string) => {
            resolve({message: "Error during loading characters for account. " + error});
        });
    });
};

export const getTopCharactersByKills = () => {

};

export const getTopDeadCharactersByKills = () => {

};


export const createCharacter : (accountID: string, nickname: string, sex: string) => Promise<ErrorVO | string> = async(accountID: string, nickname: string, sex: string) => {
    return new Promise(function(resolve, reject) {
        const collectionRef = collection(db, "Character");
        addDoc(collectionRef, {
            accountID: accountID,
            bodyparts: "11111111111111111111",
            bullets: 0,
            hp: 100,
            hunger: 100,
            inteligence: 10,
            dexterity: 10,
            luck: 10,
            strength : 10,
            kills: 0,
            message : "",
            nickname : nickname,
            quests : "00000000",
            live : true,
            sex: sex
        })
        .then((docRef) => {
            resolve(docRef.id);
        })
        .catch((error: string) => {
            resolve({ message: "Error during creating character. " + error });
        });
    });
};
    


export const characterDelete : (id: string) => Promise<ErrorVO | boolean> = async(id) => {
    return new Promise(function(resolve, reject) {
        const collectionRef = collection(db, "Character");
        deleteDoc(doc(collectionRef, id))
        .then(() => {
            resolve(true);
        })
        .catch((error: string) => {
            resolve({ message: "Error during updating character. " + error });
        });
    });
};

export const characterUpdateStats : (id: string, inteligence: number, dexterity: number, luck: number, strength: number) => Promise<ErrorVO | boolean> = async(id, inteligence, dexterity, luck, strength) => {
    return new Promise(function(resolve, reject) { 
        const collectionRef = collection(db, "Character");
        setDoc(doc(collectionRef, id), {
            inteligence: inteligence,
            dexterity: dexterity,
            luck: luck,
            strength : strength
        })
        .then(() => {
            resolve(true);
        })
        .catch((error: string) => {
            resolve({ message: "Error during updating character. " + error });
        });
    });
};

export const characterUpdateBodyparts : (id: string, bodyparts: string) => Promise<ErrorVO | boolean> = async(id, bodyparts) => {
    return new Promise(function(resolve, reject) {
        const collectionRef = collection(db, "Character");
        setDoc(doc(collectionRef, id), {
            bodyparts: bodyparts
        })
        .then(() => {
            resolve(true);
        })
        .catch((error: string) => {
            resolve({ message: "Error during updating character. " + error });
        });
    });
};

export const characterUpdateQuests : (id: string, quests: string) => Promise<ErrorVO | boolean> = async(id, quests) => {
    return new Promise(function(resolve, reject) {
        const collectionRef = collection(db, "Character");
        setDoc(doc(collectionRef, id), {
            quests: quests
        })
        .then(() => {
            resolve(true);
        })
        .catch((error: string) => {
            resolve({ message: "Error during updating character. " + error });
        });
    });
};

export const characterUpdateDie : (id: string) => Promise<ErrorVO | boolean> = async(id) => {
    return new Promise(function(resolve, reject) {
        const collectionRef = collection(db, "Character");
        setDoc(doc(collectionRef, id), {
            live: false
        })
        .then(() => {
            resolve(true);
        })
        .catch((error: string) => {
            resolve({ message: "Error during updating character. " + error });
        });
    });
};

export const characterUpdateHP : (id: string, hp: number) => Promise<ErrorVO | boolean> = async(id, hp) => {
    return new Promise(function(resolve, reject) {
        const collectionRef = collection(db, "Character");
        setDoc(doc(collectionRef, id), {
            hp: hp
        })
        .then(() => {
            resolve(true);
        })
        .catch((error: string) => {
            resolve({ message: "Error during updating character. " + error });
        });
    });
};

export const characterUpdateHungerP : (id: string, hunger: number) => Promise<ErrorVO | boolean> = async(id, hunger) => {
    return new Promise(function(resolve, reject) {
        const collectionRef = collection(db, "Character");
        setDoc(doc(collectionRef, id), {
            hunger: hunger
        })
        .then(() => {
            resolve(true);
        })
        .catch((error: string) => {
            resolve({ message: "Error during updating character. " + error });
        });
    });
};

export const characterUpdateMessage : (id: string, message: string) => Promise<ErrorVO | boolean> = async(id, message) => {
    return new Promise(function(resolve, reject) {
        const collectionRef = collection(db, "Character");
        setDoc(doc(collectionRef, id), {
            message: message
        })
        .then(() => {
            resolve(true);
        })
        .catch((error: string) => {
            resolve({ message: "Error during updating character. " + error });
        });
    });
};

export const characterUpdateKills : (id: string, kills: number) => Promise<ErrorVO | boolean> = async (id, kills) => {
    return new Promise(function(resolve, reject) {
        const collectionRef = collection(db, "Character");
        setDoc(doc(collectionRef, id), {
            kills: kills
        })
        .then(() => {
            resolve(true);
        })
        .catch((error: string) => {
            resolve({ message: "Error during updating character. " + error });
        });
    });
};
    
export const characterUpdateBullets : (id: string, bullets: number) => Promise<ErrorVO | boolean> = async (id, bullets) => {
    return new Promise(function(resolve, reject) {
        const collectionRef = collection(db, "Character");
        setDoc(doc(collectionRef, id), {
            bullets: bullets
        })
        .then(() => {
            resolve(true);
        })
        .catch((error: string) => {
            resolve({ message: "Error during updating character. " + error });
        });
    });
};

export const characterUpdateStrUP : (id: string, strength: number) => Promise<ErrorVO | boolean> = async (id, strength) => {
    return new Promise(function(resolve, reject) {
        const collectionRef = collection(db, "Character");
        setDoc(doc(collectionRef, id), {
            strength : strength
        })
        .then(() => {
            resolve(true);
        })
        .catch((error: string) => {
            resolve({ message: "Error during updating character. " + error });
        });
    });
};

export const characterUpdateDexUP : (id: string, dexterity: number) => Promise<ErrorVO | boolean> = async (id, dexterity) => {
    return new Promise(function(resolve, reject) {
        const collectionRef = collection(db, "Character");
        setDoc(doc(collectionRef, id), {
            dexterity: dexterity
        })
        .then(() => {
            resolve(true);
        })
        .catch((error: string) => {
            resolve({ message: "Error during updating character. " + error });
        });
    });
};

export const characterUpdateLucUP : (id: string, luck: number) => Promise<ErrorVO | boolean> = async (id, luck) => {
    return new Promise(function(resolve, reject) {
        const collectionRef = collection(db, "Character");
        setDoc(doc(collectionRef, id), {
            luck: luck
        })
        .then(() => {
            resolve(true);
        })
        .catch((error: string) => {
            resolve({ message: "Error during updating character. " + error });
        });
    });
};

export const characterUpdateIntUP : (id: string, inteligence: number) => Promise<ErrorVO | boolean> = async (id, inteligence) => {
    return new Promise(function(resolve, reject) {
        const collectionRef = collection(db, "Character");
        setDoc(doc(collectionRef, id), {
            inteligence: inteligence
        })
        .then(() => {
            resolve(true);
        })
        .catch((error: string) => {
            resolve({ message: "Error during updating character. " + error });
        });
    });
};