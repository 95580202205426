import React, { useState, useEffect } from 'react';

import { GUIProperties } from 'ts/Properties';

import ClaimRewardButton from 'ts/Partials/Buttons/ClaimRewardButton';
import AdvertIframe from 'ts/Components/Iframes/AdvertIframe';

import { guiActions } from 'ts/Slices/GuiSlice';

import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import BigWindow from 'ts/Components/Windows/BigWindow';
import AirdropIcon from 'ts/Icons/Crypto/AirdropIcon';

const Airdrop: React.FC = (props) => {
        
    const dispatch = useDispatch();
    
    const { t } = useTranslation("translations");
    
    const [seconds, setSeconds] = useState(30); 
    

    useEffect(() => {
        let advertInterval: NodeJS.Timeout;
        advertInterval = setInterval(() => {
            if (seconds > 0) {
                setSeconds(seconds - 1);
            }
            if (seconds === 0) {
                clearInterval(advertInterval);
            } 
        }, 1000);

        return () => {
            clearInterval(advertInterval);
        }
    }, [seconds]);

    
    const handleAdvertClaimRewardClick = () => {
        dispatch(guiActions.setWindowId(GUIProperties.Windows.Id.None));
    };

    return (
        <BigWindow 
                title={ t('advert.title') }>
            
            { seconds === 0 &&
                <div className="flex flex-col h-full w-full justify-center items-center">
                    <ClaimRewardButton 
                            handleClick = { handleAdvertClaimRewardClick } />
                </div>
            }
            { seconds > 0 &&
                <div className="flex flex-col h-full w-full justify-between items-center">
                    <div className="basis-full flex flex-col justify-center items-center">
                        <AirdropIcon className={' h-48 '} />
                        <AdvertIframe />
                    </div>
                    <span className = "text-2xl">
                        { t('advert.wait') } <span className = "text-brand-green-300">{ seconds }</span> s
                    </span>
                </div>
            }
        </BigWindow>
    );
}

export default Airdrop;
