import React from 'react';

interface HideawayIconProps {
    className: string;
}

const  HideawayIcon:React.FC<HideawayIconProps> = (props) => {
    return ( 
        <svg 
                className={props.className} 
                viewBox="0 0 92 92" 
                fill="none" 
                xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M79.8607 36.8484C79.6862 37.1547 79.4532 37.4236 79.1747 37.6397C78.8963 37.8559 78.578 38.0151 78.2381 38.1081C77.8981 38.2012 77.5431 38.2264 77.1934 38.1822C76.8437 38.1381 76.5062 38.0254 76.2 37.8507L45.903 20.6031L15.8045 37.8507C15.1868 38.2038 14.4541 38.2971 13.7676 38.1101C13.0811 37.923 12.497 37.4709 12.1438 36.8532C11.7907 36.2355 11.6974 35.5028 11.8844 34.8163C12.0715 34.1298 12.5236 33.5457 13.1413 33.1926L18.9131 29.8854V17.877C18.9156 17.2682 19.1589 16.6852 19.5899 16.2552C20.0208 15.8252 20.6043 15.5831 21.2131 15.5818H27.1301C27.7397 15.5831 28.324 15.8258 28.7551 16.2569C29.1861 16.688 29.4289 17.2722 29.4301 17.8818V23.857L44.5666 15.1896C44.9716 14.957 45.4304 14.8342 45.8975 14.8334C46.3646 14.8325 46.8238 14.9537 47.2297 15.1848L78.8535 33.1877C79.1602 33.3618 79.4296 33.5945 79.6462 33.8728C79.8629 34.151 80.0225 34.4692 80.1161 34.8092C80.2096 35.1492 80.2352 35.5043 80.1914 35.8542C80.1476 36.2041 80.0352 36.5419 79.8607 36.8484ZM73.0866 41.3854V71.3968C73.0853 72.0064 72.8426 72.5907 72.4115 73.0217C71.9804 73.4528 71.3962 73.6955 70.7866 73.6968H21.2131C20.6035 73.6955 20.0192 73.4528 19.5882 73.0217C19.1571 72.5907 18.9144 72.0064 18.9131 71.3968V41.3757L45.903 25.9197L73.0866 41.3854ZM40.2474 46.2759H29.4301V57.2288H40.2474V46.2759ZM62.5695 46.2759H51.7522V57.2288H62.5695V46.2759Z" fill="black"/>
        </svg>

    );
}

export default HideawayIcon;