import React from 'react';
import { useTranslation } from 'react-i18next';

import LeftBarWindow from 'ts/Components/Windows/LeftBarWindow';

const Bulletshop:React.FC = (props) => {

    const { t } = useTranslation();

    return (
        <LeftBarWindow title = { t('bulletshop.title') }>
        </LeftBarWindow>
    );
};

export default Bulletshop;