import React from 'react';

interface CancelIconProps {
    className: string;
}

const CancelIcon:React.FC<CancelIconProps> = (props) => {
    return (
        <svg className={props.className} viewBox="0 0 92 92" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M46 6C68.0914 6 86 23.9086 86 46C86 68.0914 68.0914 86 46 86C23.9086 86 6 68.0914 6 46C6 23.9086 23.9086 6 46 6ZM17.4286 46C17.4286 61.7796 30.2204 74.5714 46 74.5714C51.8235 74.5714 57.24 72.8292 61.7568 69.8375L22.1625 30.2432C19.1708 34.76 17.4286 40.1765 17.4286 46ZM46 17.4286C40.1765 17.4286 34.76 19.1708 30.2432 22.1625L69.8375 61.7568C72.8292 57.24 74.5714 51.8235 74.5714 46C74.5714 30.2204 61.7796 17.4286 46 17.4286Z" fill="black"/>
        </svg>


    );
}

export default CancelIcon;