import React from 'react';

import { useTranslation } from 'react-i18next';
import LeftBarWindow from 'ts/Components/Windows/LeftBarWindow';

const World:React.FC = () => {

    const { t } = useTranslation();
        
    return (
        <LeftBarWindow title =  { t('world.title') }>
        </LeftBarWindow>
    );
}

export default World;
