import React from 'react';

interface NudityIconProps {
    className: string;
}

const NudityIcon:React.FC<NudityIconProps> = (props) => {
    return ( 
        <svg 
                className={props.className} 
                viewBox="0 0 92 92" 
                fill="none" 
                xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M90.7092 73.1228L54.2631 8.82011C53.1524 6.86062 51.3825 5.35877 49.2673 4.58203C47.1528 3.80599 44.8311 3.80599 42.7159 4.58203C40.6013 5.35878 38.8307 6.86062 37.7201 8.82011L1.27395 73.1228C0.154106 75.0626 -0.244871 77.3355 0.14632 79.5406C0.538236 81.7457 1.69627 83.7412 3.41533 85.1766C5.13506 86.6112 7.30606 87.3936 9.54589 87.3844H82.4544C84.6941 87.3935 86.8651 86.6111 88.5842 85.1766C90.304 83.7412 91.4614 81.7457 91.8533 79.5406C92.2452 77.3355 91.8462 75.0626 90.7263 73.1228H90.7092ZM46.0068 76.7837C44.326 76.7837 42.7137 76.1159 41.5246 74.9268C40.3361 73.7383 39.6683 72.1261 39.6683 70.4453C39.6683 68.7637 40.3361 67.1515 41.5246 65.9631C42.7138 64.7746 44.326 64.1068 46.0068 64.1068C47.6876 64.1068 49.2999 64.7746 50.489 65.9631C51.6774 67.1516 52.3452 68.7638 52.3452 70.4453C52.3452 72.1261 51.6774 73.7384 50.489 74.9268C49.2998 76.1159 47.6876 76.7837 46.0068 76.7837ZM52.3452 54.045C52.3452 56.3094 51.137 58.402 49.176 59.5339C47.2151 60.6665 44.7985 60.6665 42.8376 59.5339C40.8766 58.402 39.6683 56.3094 39.6683 54.045V30.9404C39.6683 28.6759 40.8766 26.5834 42.8376 25.4515C44.7985 24.3189 47.2151 24.3189 49.176 25.4515C51.1369 26.5834 52.3452 28.6759 52.3452 30.9404V54.045Z" fill="black"/>
        </svg>
    );
}

export default NudityIcon;