import React from 'react';

import { useTranslation } from 'react-i18next';

import { useSelector } from 'react-redux';

import { RootState } from 'ts/Slices/Store';
import BigWindow from 'ts/Components/Windows/BigWindow';

const Profile: React.FC = (props) => {

    const { t } = useTranslation();

    const account = useSelector((state: RootState) => state.account.account);

    return (
        <BigWindow 
                title= { t('profile.title') }>
            <div className = { "grid grid-cols-2 text-xl" }>
                <span className = { "text-right mx-1" }>
                    { t('app.email') } :
                </span>
                <span className = { "text-left mx-1" }>
                    { account.email }
                </span> 
                <span className = { "text-right mx-1" }>
                    { t('profile.registrationDate') } :
                </span>
                <span className = { "text-left mx-1" }>
                    
                </span>
                <span className = { "text-right mx-1" }>
                    { t('profile.totalKills') } :
                </span>
                <span className = { "text-left mx-1" }>
                    
                </span>
                <span className = { "text-right mx-1"}>
                    { t('profile.deadChars') } :
                </span>
                <span className = { "text-left mx-1" }>

                </span>
            </div>
        </BigWindow>
    );
    
}

export default Profile;
