import React from 'react';

interface ProfileIconProps {
    className: string;
}

const ProfileIcon:React.FC<ProfileIconProps> = (props) => {
    return ( 
        <svg 
                className = { props.className }
                viewBox="0 0 92 93" 
                fill="none" 
                xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M46.0003 84.3717C67.1711 84.3717 84.3337 67.2095 84.3337 46.0384C84.3337 24.8675 67.1711 7.70508 46.0003 7.70508C24.8294 7.70508 7.66699 24.8675 7.66699 46.0384C7.66699 67.2095 24.8294 84.3717 46.0003 84.3717Z" fill="#172730"/>
            <path d="M46 26.603C38.065 26.603 31.625 33.043 31.625 40.9779C31.625 48.7595 37.72 55.0845 45.8083 55.3145C45.9233 55.3145 46.0767 55.3145 46.1533 55.3145C46.23 55.3145 46.345 55.3145 46.4217 55.3145C46.46 55.3145 46.4983 55.3145 46.4983 55.3145C54.2417 55.0462 60.3367 48.7595 60.375 40.9779C60.375 33.043 53.935 26.603 46 26.603Z" fill="#08CD91"/>
            <path d="M71.9927 74.2133C65.1694 80.4999 56.046 84.3716 46.0027 84.3716C35.9594 84.3716 26.836 80.4999 20.0127 74.2133C20.9327 70.7249 23.4244 67.5433 27.066 65.0899C37.531 58.1133 54.551 58.1133 64.9394 65.0899C68.6194 67.5433 71.0727 70.7249 71.9927 74.2133Z" fill="#07AB79"/>
        </svg>
    );
}

export default ProfileIcon;