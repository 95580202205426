import React from "react";


interface SelectedButtonProps {
    children?: React.ReactNode;
    handleClick?: Function;
    selected: boolean;
    additionalClassName?: String;
}

const SelectedButton: React.FC<SelectedButtonProps> = (props) => {

    const handleClick = () => {
        if(props.handleClick) {
            props.handleClick();
        } 
    };

    return (
        <button className = { props.additionalClassName + ` outline outline-offset-2 outline-1 hover:outline-brand-horror-teal-700 text-xl hover:font-semibold rounded duration-600 cursor-brand-link ${ props.selected && "font-semibold bg-brand-horror-teal-700 " }` }
                onClick = { handleClick }>
            { props.children }
        </button>                
    );
}

export default SelectedButton;