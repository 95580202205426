export default function ComingSoon({
    children,
    message = "Coming soon"
}: {
    children: React.ReactElement;
    message?: string;
}) {
    return(
        <div className="relative w-full">
            <div className="blur-sm">{children}</div>
            <p className={`absolute top-1/2 mx-auto w-full -translate-y-1/2 text-center text-semibold text-brand-blood-900 blur-none text-2xl`}
            >
                {message}
            </p>
        </div>
    );
}
