import React from 'react';
import { useTranslation } from 'react-i18next';

import LeftBarWindow from 'ts/Components/Windows/LeftBarWindow';

const Hideaway:React.FC = (props) => {

    const { t } = useTranslation();
    //const selectedCharacter = useSelector((state) => state.account.selectedCharacter);

    return (
        <LeftBarWindow title = { t('hideaway.title') }>
        </LeftBarWindow>
    );
}

export default Hideaway;
