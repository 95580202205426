import React from "react";

import { Fade } from "react-awesome-reveal";

interface LoginWindowProps {
    children?: React.ReactNode;
}

const LoginWindow: React.FC<LoginWindowProps> = (props) => {

    return (
        <Fade className="w-full h-full">
            <div className="flex justify-center items-center h-full">
                <div className="flex flex-col max-w-sm w-full bg-opacity-40 hover:bg-opacity-60 bg-brand-gray-900 bg-scratches1 bg-no-repeat bg-top py-8 px-16 mx-auto rounded-xl border-2 border-brand-horror-teal-300 outline outline-2 outline-offset-2 hover:outline-offset-2 outline-brand-horror-cyan-300 hover:outline-brand-horror-cyan-500 cursor-brand-default">
                    { props.children }
                 </div>
            </div>
        </Fade>
    );
}

export default LoginWindow;