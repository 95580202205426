import { ErrorVO, SettingsVO } from 'ts/Slices/AccountSlice';
import db from '../Firebase'
import { collection, doc, setDoc, addDoc, getDocs, deleteDoc , query, where } from 'firebase/firestore';

// Settings
export const getSettingsByAccountId: (accountID: string) => Promise<ErrorVO | SettingsVO> = async (accountID) => {
    return new Promise(function(resolve, reject) {
        const collectionRef = collection(db, "Settings");
        const q = query(collectionRef, where('accountID', '==', accountID));
        getDocs(q).then((snapshot) => {
           if (snapshot.empty) {
                resolve({ message : "Settings not found."});
            }

            if(snapshot.size === 1) {
                resolve({ ...snapshot.docs[0].data(), id:snapshot.docs[0].id } as SettingsVO);
            } else {
                resolve({ message : "This account is having invalid records in db."});
            }
        }).catch((error: string) => {
            resolve({ message : "Error during loading settings. " + error });
        });
    });
};

export const createSettings: (accountID: string, language: string) => Promise<ErrorVO | string> = (accountID, language) => {
    return new Promise(function(resolve, reject) {
        const collectionRef = collection(db, "Settings");
        addDoc(collectionRef, {
            accountID: accountID,
            language: language
        })
        .then((docRef) => {
            resolve(docRef.id);
        })
        .catch(function(error: string) {
            resolve({ message : "Error during creating settings. " + error });
        });
    });
};

export const deleteSettings: (id: string) => Promise<ErrorVO | boolean> = (id) => {
    return new Promise(function(resolve, reject) {
        const collectionRef = collection(db, "Settings");
        deleteDoc(doc(collectionRef, id))
        .then(() => {
            resolve(true);
        })
        .catch((error: string) => {
            resolve({ message: "Error during deleting settiungs. " + error });
        });
    });
    
};

export const updateSettings: (id: string, language: string) => Promise<ErrorVO | boolean> = (id: string, language: string) => {
    return new Promise(function(resolve, reject) {
        const collectionRef = collection(db, "Settings");
        setDoc(doc(collectionRef, id), {
            language: language
        })
        .then(() => {
            resolve(true);
        })
        .catch(function(error: string) {
            resolve({ message: "Error during updating settings. " + error });
        });
    });
};

