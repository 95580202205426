import React from 'react';

interface MusicIconProps {
    className: string;
    percent: number;
}

const MusicIcon:React.FC<MusicIconProps> = (props) => {
    return (
        <svg 
                className={props.className} 
                viewBox="0 0 92 92" 
                fill="none" 
                xmlns="http://www.w3.org/2000/svg"    
        >
            <path d="M65.167 19.7417C60.6675 16.5 56.472 12.8561 52.632 8.85504C52.1059 8.30374 51.4274 7.92176 50.6831 7.75789C49.9389 7.59402 49.1627 7.6557 48.4537 7.93504C47.7333 8.21303 47.1136 8.70211 46.6758 9.33823C46.2381 9.97435 46.0026 10.7279 46.0003 11.5V52.7467C42.8025 50.6101 38.9824 49.6034 35.147 49.8865C31.3115 50.1697 27.6806 51.7265 24.8313 54.3095C21.9819 56.8926 20.0775 60.3538 19.4205 64.1432C18.7636 67.9326 19.3918 71.8328 21.2054 75.2243C23.0191 78.6157 25.914 81.3038 29.4303 82.8615C32.9467 84.4192 36.8827 84.7571 40.6131 83.8216C44.3435 82.8861 47.6543 80.7307 50.0194 77.6981C52.3846 74.6654 53.6684 70.9293 53.667 67.0834V20.47C55.9463 22.556 58.3811 24.4654 60.9503 26.1817C64.132 28.2517 66.9687 31.5867 63.787 40.94C63.4617 41.9059 63.5333 42.9614 63.9862 43.8744C64.4391 44.7874 65.2362 45.483 66.202 45.8084C67.1678 46.1337 68.2234 46.062 69.1363 45.6091C70.0493 45.1562 70.745 44.3592 71.0703 43.3934C74.712 32.5834 72.8337 24.6484 65.167 19.7417Z" fill="black"/>
            { props.percent < 25 && 
                <path d="M72.8331 84.3334C72.2477 84.332 71.6704 84.1965 71.1455 83.9374C70.6205 83.6782 70.1619 83.3023 69.8048 82.8384L16.1381 13.8384C15.5905 13.0308 15.3715 12.0448 15.5257 11.0813C15.6799 10.1178 16.1958 9.24934 16.9681 8.653C17.7404 8.05665 18.7111 7.77727 19.6822 7.8718C20.6534 7.96633 21.552 8.42765 22.1948 9.16173L75.8615 78.1617C76.4797 78.9656 76.7543 79.9817 76.625 80.9876C76.4956 81.9934 75.973 82.9071 75.1715 83.5284C74.5024 84.0477 73.6801 84.3308 72.8331 84.3334Z" fill="#07AB79"/>
            }
        </svg>
    );
}

export default MusicIcon;