import React from "react";
import Button from "./Button";

interface IconButtonProps {
    handleClick?: Function;
    icon: React.ReactNode;
    disabled?: boolean;
    text: String;
}

const IconButton: React.FC<IconButtonProps> = (props) => {

    const handleClick = () => {
        if(props.handleClick) {
            props.handleClick();
        } 
    };

    return (
        <Button additionalClassName = { "w-full flex h-12 my-1 flex-col items-center justify-center hover:text-brand-horror-turqoise-900" } 
                handleClick = { handleClick }
                disabled = { props.disabled }> 
            <div className="relative w-full shrink-0 h-12 flex items-center justify-center opacity-40 ease-in-out transition-opacity transition-colors duration-600 translate-y-6 mx-2">
                { props.icon }
            </div>
            <span className="relative w-full shrink-0 h-12 flex items-center justify-center text-2xl -translate-y-6 opacity-80 hover:opacity-10 ease-in-out transition-opacity transition-colors duration-600 mx-2">{ props.text }</span>
            
        </Button>               
    );
}

export default IconButton;