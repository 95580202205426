import React, { Fragment } from 'react';

import BulletIcon from 'ts/Icons/Crypto/BulletIcon';

import { useSelector } from 'react-redux';
import { RootState } from 'ts/Slices/Store';
import { useTranslation } from 'react-i18next';

import LeftBarWindow from 'ts/Components/Windows/LeftBarWindow';

interface CharacterProps {
    gameSendMessage:Function;
}

const Stash:React.FC<CharacterProps> = (props) => {

    const { t } = useTranslation();
    const selectedCharacter = useSelector((state: RootState) => state.account.selectedCharacter);

    return (
        <LeftBarWindow title = { t('character.title') }>       
            <Fragment>            
                <div className = { "w-1/2 grid grid-cols-2 items-center" }>
                    <span className = { "text-right m-2" }>
                        { t('characters.nickname') }<span> : </span>
                    </span>
                    <span className = { "text-left m-2" }>
                        { selectedCharacter.nickname }
                    </span>
                    <span className="m-2 ml-auto flex">
                        <BulletIcon className={'h-6 fill-black '} /><span> : </span>
                    </span>
                    <span className = { "text-left m-2" }>
                        { selectedCharacter.bullets }
                    </span>
                </div>
            </Fragment>
        </LeftBarWindow>
    );
    
}

export default Stash;
