import React, { useRef, useState } from 'react';

import { CharacterProperties } from 'ts/Properties';

import { getCharactersByAccountId, createCharacter, characterDelete } from 'ts/Services/CharacterService';

import { accountActions, AccountVO, CharacterVO, ErrorVO } from 'ts/Slices/AccountSlice';

import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'ts/Slices/Store';

import { useTranslation } from 'react-i18next';

import { guiActions } from 'ts/Slices/GuiSlice';
import LeftBarWindow from 'ts/Components/Windows/LeftBarWindow';
import MaleIcon from 'ts/Icons/Forms/MaleIcon';
import FemaleIcon from 'ts/Icons/Forms/FemaleIcon';
import ArrowLeftIcon from 'ts/Icons/Forms/ArrowLeftIcon';
import ArrowRightIcon from 'ts/Icons/Forms/ArrowRightIcon';
import Button from 'ts/Components/Buttons/Button';
import SelectableButton from 'ts/Components/Buttons/SelectableButton';
import IconButton from 'ts/Components/Buttons/IconButton';
import NewCharacterIcon from 'ts/Icons/Main/NewCharacterIcon';
import RemoveIcon from 'ts/Icons/Main/RemoveIcon';
import SaveIcon from 'ts/Icons/Forms/SaveIcon';
import CancelIcon from 'ts/Icons/Forms/CancelIcon';

interface CharactersProps {
    gameSendMessage:Function;
}

const Characters:React.FC<CharactersProps> = (props) => {
    
    const dispatch = useDispatch();

    const { t } = useTranslation("translations");

    const characters : CharacterVO[] = useSelector((state : RootState) => state.account.characters);
    const selectedCharacter : CharacterVO = useSelector((state : RootState) => state.account.selectedCharacter);
    const account : AccountVO = useSelector((state : RootState) => state.account.account);
        
    const newCharacterErrorRef = useRef<HTMLSpanElement>(null);

    const [mode, setMode] = useState(CharacterProperties.Modes.Selection);
    const [newCharacter, setNewCharacter] = useState<CharacterVO>({
        nickname : "",
        sex : CharacterProperties.Sex.Male,
        id: "",
        accountID: "",
        bodyparts: "",
        bullets: 0,
        hp: 0,
        hunger: 0,
        inteligence: 0,
        dexterity: 0,
        luck: 0,
        strength: 0,
        kills: 0,
        message: "",
        quests: "",
        live: true,
    });

    /**
     * Selection
     */
    const handleSelectChar_New = () => {
        let changedCharacter : CharacterVO = {
            nickname : "",
            sex : CharacterProperties.Sex.Male,
            id: "",
            accountID: "",
            bodyparts: "",
            bullets: 0,
            hp: 0,
            hunger: 0,
            inteligence: 0,
            dexterity: 0,
            luck: 0,
            strength: 0,
            kills: 0,
            message: "",
            quests: "",
            live: true,
        };
        setNewCharacter(changedCharacter);
        setMode(CharacterProperties.Modes.New);

        dispatch(accountActions.setSelectedCharacter(null));

        props.gameSendMessage("CharactersSelectCharacter", changedCharacter.sex);
    };

    const handleSelectChar_RemoveConfirm = async() => {
        await characterDelete(selectedCharacter.id);
        
        for(let i = 0; i < characters.length; i++) {
            if(characters[i].id === selectedCharacter.id) {
                characters.splice(i, 1);
            }
        }

        dispatch(accountActions.setCharacters(characters));
        dispatch(accountActions.setSelectedCharacter(null));
        
        props.gameSendMessage("CharactersUnselectCharacter");
        
        dispatch(guiActions.hideConfirmBox());
    };
    const handleSelectChar_Remove = async() => {
        dispatch(guiActions.showConfirmBox({
                confirmCallback:handleSelectChar_RemoveConfirm, 
                confirmLabel: t('characters.deleteConfirmMessage')
            }
        ));
    };

    const handleSelectChar_Select = (selectedCharacter : CharacterVO) => {
        dispatch(accountActions.setSelectedCharacter(selectedCharacter));
        
        props.gameSendMessage("CharactersSelectCharacter", selectedCharacter.sex);
    };

    /**
     * New
     */
    const handleNewChar_SexChange = () => {
        let changedCharacter : CharacterVO = {
            ...newCharacter
        };
        if(changedCharacter.sex === CharacterProperties.Sex.Male) {
            changedCharacter.sex = CharacterProperties.Sex.Female;
        } else {
            changedCharacter.sex = CharacterProperties.Sex.Male;
        }
        setNewCharacter(changedCharacter);

                
        props.gameSendMessage("CharactersSelectCharacter", newCharacter.sex);
    };

    const handleNewChar_Save = async () => {            
        let valid = true;
        const nickname = (document.getElementById("NewCharacterNickname") as HTMLInputElement)?.value;
        if(!(nickname != null && nickname.length > 2)) {
            (document.getElementById("NewCharacterError") as HTMLDivElement).innerHTML = t('error.characters.new.nicknameShort');
            valid = false;
        }

        /* TODO IF CHARACTER EXISTS */


        if(valid) {
            let characterResult = await createCharacter(account.id, nickname, newCharacter.sex);
            let charactersResult: ErrorVO | CharacterVO[] = await getCharactersByAccountId(account.id);
            
            if(charactersResult as CharacterVO[] && characterResult as CharacterVO) {
                let character = characterResult as CharacterVO;
                let characters = charactersResult as CharacterVO[];
                dispatch(accountActions.setCharacters(characters));
                for(let i = 0; i < characters.length; i++) {
                    if(characters[i].id === character.id) {
                        dispatch(accountActions.setSelectedCharacter(characters[i]));
                    }
                }
            } else if(charactersResult as ErrorVO) {

            }
    
            setMode(CharacterProperties.Modes.Selection);
        }
    };

    const handleNewChar_Cancel = () => {
        setMode(CharacterProperties.Modes.Selection);

        dispatch(accountActions.setSelectedCharacter(null));
        
        props.gameSendMessage("CharactersUnselectCharacter");
    };
    
    if(mode === CharacterProperties.Modes.Selection) {
        /** 
         * Character selection
         **/
        const characterList = [];
        if(characters != null) {
            for (const character of characters.values()) {
                if(selectedCharacter != null && selectedCharacter.nickname === character.nickname) {
                    characterList.push(
                        <SelectableButton additionalClassName = { "w-full h-8 my-1 py-1 outline-brand-horror-teal-500" }
                                selected={true} >
                            <span>{ character.nickname }</span>
                        </SelectableButton>
                    );
                }
                else {
                    characterList.push(
                        <SelectableButton additionalClassName = { "w-full h-8 my-1 py-1 outline-brand-horror-teal-300" }
                                selected={false}
                                handleClick = { () => handleSelectChar_Select(character) }>
                            <span>{ character.nickname }</span>
                        </SelectableButton>
                    );
                }
            }
        }
        return (
            <LeftBarWindow title = { t('characters.title') }>
                    <div className="mb-4 flex flex-col w-full items-center">
                        { characterList }
                    </div>
                    <div className="basis-full">

                    </div>
                    <IconButton icon = { <NewCharacterIcon className={'h-8 fill-current shrink-0'} /> }
                            handleClick = { handleSelectChar_New }
                            text = { t('characters.new') } />
                    <IconButton icon = { <RemoveIcon className={'h-8 fill-current shrink-0'} /> }
                            handleClick = { handleSelectChar_Remove }
                            disabled={selectedCharacter === null}
                            text = { t('characters.delete') } />
                
            </LeftBarWindow>
        );
    } else if (mode === CharacterProperties.Modes.New) {
        /** 
         * New character
         **/
        return (
            <LeftBarWindow title = { t('characters.new')  }>
                <span ref = { newCharacterErrorRef }
                        className = { "text-red-500 w-full h-8" } >        
                </span>
                <input id = "NewCharacterNickname" 
                        className = { "w-full mb-4 w-full rounded-xl h-10 px-4 text-brand-black cursor-brand-text" } 
                        placeholder = { t('characters.nickname') } />                        
                <div className = { "flex w-full justify-between" }>
                    <Button additionalClassName = { "mr-8" }
                            handleClick = { handleNewChar_SexChange }>
                        <ArrowLeftIcon className={'fill-black h-6 mx-2'} />
                    </Button>
                    { newCharacter.sex === CharacterProperties.Sex.Male &&
                        <div className="flex w-full justify-between">
                            <span className = { "text-xl" } >
                                { t('characters.male') }
                            </span>
                            <MaleIcon className={'fill-black h-8'} />
                        </div>
                    }
                    { newCharacter.sex === CharacterProperties.Sex.Female &&
                        <div className="flex w-full justify-between">
                            <span className = { "text-xl" } >
                                { t('characters.female') }
                            </span>
                            <FemaleIcon className={'fill-black h-8'} />
                        </div>
                    }
                    <Button additionalClassName = { "ml-8" }
                            handleClick = { handleNewChar_SexChange }>
                        <ArrowRightIcon className={'fill-black mx-2 h-6'} />
                    </Button>
                </div>
                <div className="basis-full"></div>
                <IconButton icon = { <SaveIcon className={'h-8 fill-current shrink-0'} /> }
                            handleClick = { handleNewChar_Save }
                            text = { t('characters.save') } />
                <IconButton icon = { <CancelIcon className={'h-8 fill-current shrink-0'} /> }
                            handleClick = { handleNewChar_Cancel }
                            text = { t('app.cancel') } />
            </LeftBarWindow>
        );
    }
    return (<></>);
}

export default Characters;