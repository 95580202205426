import React from 'react';

interface LeaveHideawayIconProps {
    className: string;
}

const LeaveHideawayIcon:React.FC<LeaveHideawayIconProps> = (props) => {
    return (    
		<svg className={props.className} viewBox="0 0 92 92" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M27.9971 0V31.2457H34.0017V6.00462H76.3346L62.8814 15.1069C61.3596 16.1359 60.4494 17.8541 60.4494 19.6894V71.1232H34.0017V50.6861H27.9971V77.1278H60.4494V89.873C60.4494 90.6604 60.8847 91.3831 61.5796 91.751C62.2744 92.1204 63.1174 92.0764 63.7698 91.6352L85.2098 77.128V0H27.9971Z" fill="black"/>
			<path d="M41.0865 52.1813C41.0865 52.5126 41.2784 52.8145 41.5806 52.954C41.8796 53.0932 42.2343 53.0463 42.4866 52.8309L56.5101 40.9652L42.4866 29.0997C42.2343 28.8841 41.8796 28.8374 41.5806 28.9764C41.2785 29.1157 41.0865 29.4178 41.0865 29.7491V35.7492H6.79004V46.1826H41.0866V52.1813H41.0865Z" fill="black"/>
		</svg>	

    );
}

export default LeaveHideawayIcon;