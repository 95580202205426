import React from 'react';

interface WorldIconProps {
    className: string;
}

const WorldIcon:React.FC<WorldIconProps> = (props) => {
    return ( 
        <svg className={props.className} 
                viewBox="0 0 92 92" 
                fill="none" 
                xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M52.9766 47.8174L91.9953 47.8801L92 44.0462L83.4812 44.0327V28.2101L80.9769 23.5245L78.1558 28.2101V44.0253L74.7493 44.0192V10.8023H73.0288V5H71.7854V10.8023H69.3196V5.05331H68.0412V10.8023H66.657V44.0089L61.1223 43.9994V19.9589H54.0942V43.9891L51.1108 43.983V24.8575H44.297V39.55H35.1393V43.9587L30.242 43.9513V11.8661H22.7893V20.1721L17.5727 17.9367L11.7165 20.1721V43.9211L8.4683 43.915V40.7733H1.76092V43.9036L0.00673904 43.901L0 47.7348L39.124 47.7975L0.087984 91.9349H43.6923L43.85 83.7226H46.8797L47.0361 91.9349H91.9981L52.9766 47.8174ZM45.3621 48.2804L45.445 49.5574H45.2778L45.3621 48.2804ZM45.1188 51.5798H45.604L45.7637 53.177H44.9598L45.1188 51.5798ZM45.0413 55.3071H45.6808L45.8614 57.8026L44.7832 57.7763L45.0413 55.3071ZM44.482 61.7826H46.2401L46.3985 65.4412H44.3236L44.482 61.7826ZM44.0055 77.5155L44.1652 71.8621H46.5582L46.718 77.5155H44.0055Z" fill="black"/>
        </svg>

    );
}

export default WorldIcon;