import React, { useRef } from 'react';

import { useTranslation } from 'react-i18next';

import { useDispatch } from 'react-redux';

import { AppProperties, GUIProperties } from 'ts/Properties';

import { signIn } from 'ts/Services/AuthService';
import { getAccountByEmail } from 'ts/Services/AccountService';
import { getSettingsByAccountId } from 'ts/Services/SettingsService';
import { getStashByAccountId } from 'ts/Services/StashService';
import { getCharactersByAccountId } from 'ts/Services/CharacterService';

import { guiActions } from 'ts/Slices/GuiSlice';
import { accountActions, AccountVO, CharacterVO, ErrorVO, SettingsVO, StashVO } from 'ts/Slices/AccountSlice';

import LoginWindow from 'ts/Components/Windows/LoginWindow';
import DeadWebsiteIcon from 'ts/Icons/Main/DeadWebsiteIcon';
import LogInIcon from 'ts/Icons/Forms/LogInIcon';
import RegistrationIcon from 'ts/Icons/Forms/RegistrationIcon';
import IconButton from 'ts/Components/Buttons/IconButton';


interface LoginProps {
    gameSendMessage:Function;
}

const Login: React.FC<LoginProps> = (props) => {

    const dispatch = useDispatch();
    
    const { t } = useTranslation();
    
    
    const loginErrorRef = useRef<HTMLSpanElement>(null);
    const loginEmailInputRef = useRef<HTMLInputElement>(null);
    const loginPasswordInputRef = useRef<HTMLInputElement>(null);

    const handleLoginClick = async() => {
        dispatch(guiActions.setLoading(true));
        const email = loginEmailInputRef.current?.value;
        const password = loginPasswordInputRef.current?.value;
        if(email !== undefined && password !== undefined) {
            let signInResponse = await signIn(email, password);
            if(typeof signInResponse === 'boolean') {
                let accountResult : ErrorVO | AccountVO = await getAccountByEmail(email);
                if(accountResult as AccountVO) {
                    let account : AccountVO = accountResult as AccountVO;
                    let stashResult : ErrorVO | StashVO = await getStashByAccountId(account.id);
                    let settingsResult : ErrorVO | SettingsVO = await getSettingsByAccountId(account.id);
                    let charactersResult : ErrorVO | CharacterVO[] = await getCharactersByAccountId(account.id);
                    if(stashResult as StashVO && settingsResult as SettingsVO && charactersResult as CharacterVO[]) {
                        let stash : StashVO = stashResult as StashVO;
                        let settings : SettingsVO = settingsResult as SettingsVO;
                        let characters : CharacterVO[] = charactersResult as CharacterVO[];
                        dispatch(accountActions.setAccount(account));
                        dispatch(accountActions.setStash(stash));
                        dispatch(accountActions.setSettings(settings));
                        dispatch(accountActions.setCharacters(characters));
                        dispatch(guiActions.setAppState(AppProperties.AppStates.Characters));
                        dispatch(guiActions.setWindowId(GUIProperties.Windows.Id.Characters));
                        props.gameSendMessage("ToCharacters");
                    } else if (stashResult as ErrorVO) {
                        let errorMessage = stashResult as ErrorVO;
                        if(loginErrorRef.current) {
                            loginErrorRef.current.innerHTML = errorMessage.message; 
                        }
                    } else if (settingsResult as ErrorVO) {
                        let errorMessage = settingsResult as ErrorVO;
                        if(loginErrorRef.current) {
                            loginErrorRef.current.innerHTML = errorMessage.message; 
                        }
                    } else if (charactersResult as ErrorVO) {
                        let errorMessage = charactersResult as ErrorVO;
                        if(loginErrorRef.current) {
                            loginErrorRef.current.innerHTML = errorMessage.message; 
                        }
                    }
                } else if (accountResult as ErrorVO) {
                    let errorMessage = accountResult as ErrorVO;
                    if(loginErrorRef.current) {
                        loginErrorRef.current.innerHTML = errorMessage.message; 
                    }
                }
            } else if (signInResponse as ErrorVO) {
                let errorMessage = signInResponse as ErrorVO;
                if(loginErrorRef.current) {
                    loginErrorRef.current.innerHTML = errorMessage.message; 
                }

                //(document.getElementById("LoginError") as HTMLDivElement).innerHTML = t('error.login.wrongEmailOrPassword');
            }
        }
        dispatch(guiActions.setLoading(false));
    };

    const handleCreateNewAccountClick = () => {
        dispatch(guiActions.setWindowId(GUIProperties.Windows.Id.Registration));
    };


    return (
        <LoginWindow>
            <DeadWebsiteIcon className={ "my-1 mx-auto w-full " } />
            <span className="text-5xl text-bold text-center text-brand-blood-500">DEAD WEBSITE</span>
            <span id="LoginError" 
                    ref={loginErrorRef}  
                    className={ "my-4 text-center text-red-500 h-8" }>
            </span>
            <input 
                    ref={loginEmailInputRef} 
                    className={ "my-4 w-full rounded-xl h-10 px-4 text-brand-black cursor-brand-text" }
                    placeholder = { t('app.email') } />
            <input 
                    ref={loginPasswordInputRef} 
                    className={ "my-4 w-full rounded-xl h-10 px-4 text-brand-black cursor-brand-text" }
                    placeholder = { t('app.password') } 
                    type = "password" />
            <IconButton text = { t('app.login') }
                    handleClick = { handleLoginClick }
                    icon = { <LogInIcon className='h-8 fill-current' /> } />
            <IconButton text = { t('app.button.createNewAccount') }
                    handleClick = { handleCreateNewAccountClick }
                    icon = { <RegistrationIcon className='h-8 fill-current' /> } />
        </LoginWindow>
    );
}

export default Login;
