import React, { useRef, Fragment } from 'react';

import GUI from './GUI';
import Game from './Partials/Game/Game';

const App:React.FC = () => {
    /*
        const [width, setWidth] = useState<Number>(window.innerWidth);
        const [height, setHeight] = useState<Number>(window.innerHeight);

        useEffect(() => {
            window.addEventListener('resize', updateDimensions);

            return () => {
                window.removeEventListener('resize', updateDimensions);
            }
        }, []);
        
        const updateDimensions = () => {
            setWidth(window.innerWidth);
            setHeight(window.innerHeight);
        };
    */
    type GameHandle = React.ElementRef<typeof Game>;

    const gameRef = useRef<GameHandle>(null);

    const gameSendMessage = (functionName : string, payload : string) => {
        gameRef.current?.gameSendMessage(functionName, payload);
    }

    return (
        <Fragment>
            <Game ref = { gameRef }/>
            <GUI gameSendMessage = { gameSendMessage } />
        </Fragment>
    );
}


export default App;