import { RootState } from 'ts/Slices/Store';
import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import ConfirmBox from './ConfirmBox';
import Loading from './Loading';

const Modals: React.FC = (props) => {
        
    const loading = useSelector((state : RootState) => state.gui.loading);
    const gameLoading = useSelector((state : RootState) => state.game.gameLoading);
    const confirmBox = useSelector((state : RootState) => state.gui.confirmBox);
    
    return (
        <Fragment>
            { (loading || gameLoading) &&
                <Loading />
            }
            { (!(loading || gameLoading) && confirmBox) && 
                <ConfirmBox />
            }
        </Fragment>
    );
}

export default Modals;