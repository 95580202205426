import React from 'react';

interface NewCharacterIconProps {
    className: string;
}

const NewCharacterIcon:React.FC<NewCharacterIconProps> = (props) => {
    return (    
        <svg 
                className={props.className} 
                viewBox="0 0 92 92" 
                xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M51.75 46C61.2357 46 69.6163 37.0677 70.4375 26.087C70.8454 20.5706 69.115 15.4262 65.5644 11.6042C62.0515 7.82898 57.1406 5.75 51.75 5.75C46.3163 5.75 41.4018 7.81641 37.9141 11.5683C34.3868 15.3615 32.6672 20.5167 33.0625 26.0834C33.8693 37.0659 42.2481 46 51.75 46Z" fill="currentColor" />
            <path d="M89.0137 79.0194C87.4971 70.6064 82.7624 63.5393 75.3233 58.5799C68.7162 54.1758 60.3445 51.75 51.7501 51.75C43.1556 51.75 34.784 54.1758 28.1769 58.5781C20.7378 63.5375 16.0031 70.6046 14.4865 79.0176C14.1397 80.9456 14.6105 82.8521 15.7785 84.2483C16.3082 84.8845 16.9734 85.3944 17.7254 85.7407C18.4774 86.0871 19.2973 86.261 20.1251 86.25H83.3751C84.2034 86.2618 85.0239 86.0884 85.7765 85.7423C86.5292 85.3963 87.195 84.8865 87.7253 84.2501C88.8897 82.8539 89.3605 80.9474 89.0137 79.0194Z" fill="#07AB79"/>
            <path d="M18.6875 51.75V44.5625H25.875C26.6375 44.5625 27.3688 44.2596 27.9079 43.7204C28.4471 43.1813 28.75 42.45 28.75 41.6875C28.75 40.925 28.4471 40.1937 27.9079 39.6546C27.3688 39.1154 26.6375 38.8125 25.875 38.8125H18.6875V31.625C18.6875 30.8625 18.3846 30.1312 17.8454 29.5921C17.3063 29.0529 16.575 28.75 15.8125 28.75C15.05 28.75 14.3187 29.0529 13.7796 29.5921C13.2404 30.1312 12.9375 30.8625 12.9375 31.625V38.8125H5.75C4.9875 38.8125 4.25623 39.1154 3.71707 39.6546C3.1779 40.1937 2.875 40.925 2.875 41.6875C2.875 42.45 3.1779 43.1813 3.71707 43.7204C4.25623 44.2596 4.9875 44.5625 5.75 44.5625H12.9375V51.75C12.9375 52.5125 13.2404 53.2438 13.7796 53.7829C14.3187 54.3221 15.05 54.625 15.8125 54.625C16.575 54.625 17.3063 54.3221 17.8454 53.7829C18.3846 53.2438 18.6875 52.5125 18.6875 51.75Z" fill="currentColor"/>
        </svg>
    );
}

export default NewCharacterIcon;