import React from 'react';

interface BulletIconProps {
    className: string;
}

const BulletIcon:React.FC<BulletIconProps> = (props) => {
    return (    
        <svg className={props.className}
                xmlns="http://www.w3.org/2000/svg" 
	            viewBox="0 0 503.187 503.187">
	        <polygon points="292.561,460.22 292.561,435.039 208.626,435.039 208.626,460.22 200.233,460.22 
		        200.233,493.793 300.954,493.793 300.954,460.22 	"/>
	        <polygon points="317.741,460.22 317.741,435.039 292.561,435.039 292.561,460.22 300.954,460.22 
		        300.954,493.793 326.134,493.793 326.134,460.22 	"/>
	        <polygon points="208.626,460.22 208.626,435.039 183.446,435.039 183.446,460.22 175.052,460.22 
		        175.052,493.793 200.233,493.793 200.233,460.22 	"/>
	        <polygon points="200.233,435.039 300.954,435.039 300.954,183.236 200.233,183.236 	"/>
	        <polygon points="175.052,435.039 200.233,435.039 200.233,183.236 175.052,183.236 	"/>
	        <polygon points="300.954,435.039 326.134,435.039 326.134,183.236 300.954,183.236 	"/>
	        <path d="M292.561,183.236h-83.934v-41.967c0-52.879,15.948-102.4,41.967-134.295l0,0l0,0
		        c26.02,31.895,41.967,81.416,41.967,134.295V183.236z"/>
	        <path d="M183.446,141.269v41.967h25.18v-41.967c0-52.879,15.948-102.4,41.967-134.295
		        C208.626,38.869,183.446,88.39,183.446,141.269"/>
	        <path d="M250.593,6.974c26.02,31.895,41.967,81.416,41.967,134.295v41.967h25.18v-41.967
		        C317.741,88.39,292.561,38.869,250.593,6.974"/>
	        <path d="M326.134,443.433H175.052c-5.036,0-8.393-3.357-8.393-8.393V183.236c0-5.036,3.357-8.393,8.393-8.393h151.082
		        c5.036,0,8.393,3.357,8.393,8.393v251.803C334.528,440.075,331.17,443.433,326.134,443.433z M183.446,426.646h134.295V191.63
		        H183.446V426.646z"/>
	        <path d="M317.741,468.613H183.446c-5.036,0-8.393-3.357-8.393-8.393v-25.18c0-5.036,3.357-8.393,8.393-8.393h134.295
            	c5.036,0,8.393,3.357,8.393,8.393v25.18C326.134,465.256,322.777,468.613,317.741,468.613z M191.839,451.826h117.508v-8.393
		        H191.839V451.826z"/>
	        <path d="M326.134,502.187H175.052c-5.036,0-8.393-3.357-8.393-8.393V460.22c0-5.036,3.357-8.393,8.393-8.393h151.082
		        c5.036,0,8.393,3.357,8.393,8.393v33.574C334.528,498.829,331.17,502.187,326.134,502.187z M183.446,485.4h134.295v-16.787H183.446
		        V485.4z"/>
	        <path d="M317.741,191.63H183.446c-5.036,0-8.393-3.357-8.393-8.393v-41.967c0-54.557,26.02-107.436,70.505-141.01
		        c3.357-1.679,6.715-1.679,10.072,0c44.485,33.574,70.505,86.452,70.505,141.01v41.967
		        C326.134,188.272,322.777,191.63,317.741,191.63z M191.839,174.843h117.508v-33.574c0-47.843-21.823-93.167-58.754-123.384
		        c-36.931,30.216-58.754,75.541-58.754,123.384V174.843z"/>
        </svg>
    );
}

export default BulletIcon;