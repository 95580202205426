import React, { Fragment } from 'react';

import { useTranslation } from 'react-i18next';

import { useSelector, useDispatch } from 'react-redux';
import ContinueIcon from 'ts/Icons/Forms/ContinueIcon';
import CharacterIcon from 'ts/Icons/Main/CharacterIcon';
import LeaveHideawayIcon from 'ts/Icons/Main/LeaveHideawayIcon';

import { AppProperties, GUIProperties } from 'ts/Properties';

import { guiActions } from 'ts/Slices/GuiSlice';
import { RootState } from 'ts/Slices/Store';
import Button from './Button';
import IconButton from './IconButton';

interface MainButtonProps {
    gameSendMessage:Function;
}

const MainButton: React.FC<MainButtonProps> = (props) => {

    const dispatch = useDispatch();
    
    const { t } = useTranslation();

    const appState = useSelector((state:RootState) => state.gui.appState);

    const handleCharctersMainButtonClick = () => {
        dispatch(guiActions.setLoading(true));
        dispatch(guiActions.setWindowId(GUIProperties.Windows.Id.None));
        if(true) { // TODO
            dispatch(guiActions.setAppState(AppProperties.AppStates.Hideaway));
            props.gameSendMessage("ToHideaway", "Home");
        } else {
            //props.setAppState(AppProperties.AppStates.WorldPause);            
            //props.gameRef.current.pauseGame(); 
        }
        dispatch(guiActions.setLoading(false));
    };
    
    const handleHideawayMainButtonClick = () => {
        dispatch(guiActions.setLoading(true));
        dispatch(guiActions.setWindowId(GUIProperties.Windows.Id.None));
        dispatch(guiActions.setAppState(AppProperties.AppStates.WorldPlay));
        
        props.gameSendMessage("ToWorldPlay");

        dispatch(guiActions.setLoading(false));
        props.gameSendMessage("ResumeGame");
    };

    const handleWorldMainButtonClick = () => {
        dispatch(guiActions.setWindowId(GUIProperties.Windows.Id.None));
        dispatch(guiActions.setAppState(AppProperties.AppStates.WorldPlay));
        
        props.gameSendMessage("ResumeGame");
    };

    const handleMainButtonClick = () => {
        if(appState === AppProperties.AppStates.Characters) { 
            handleCharctersMainButtonClick();
        }
        else if(appState === AppProperties.AppStates.Hideaway) {
            handleHideawayMainButtonClick();
        }
        else if(appState === AppProperties.AppStates.WorldPause) {       
            handleWorldMainButtonClick();
        }
    }

    let buttonLabel = "";
    let buttonIcon;
    if(appState === AppProperties.AppStates.Characters) { 
        buttonLabel = t('app.button.selectCharacter');
        buttonIcon = <CharacterIcon className={'h-10 shrink-0 fill-current'} />
    }
    else if(appState === AppProperties.AppStates.Hideaway) {
        buttonLabel = t('app.button.leaveHideaway');
        buttonIcon = <LeaveHideawayIcon className={'h-10 shrink-0 fill-current'} />
    }
    else if(appState === AppProperties.AppStates.WorldPause) {       
        buttonLabel = t('app.button.continue');
        buttonIcon = <ContinueIcon className={'h-10 shrink-0 fill-current'} />
    }

    return (
        <div className = { "fixed w-full bottom-48 flex justify-center items-center z-10" } >
            <div className = "w-64 rounded px-1 outline outline-offset-1 outline-1 hover:outline-brand-horror-teal-700 ">
                <IconButton text={ buttonLabel }  
                        handleClick = { handleMainButtonClick }
                        icon = { buttonIcon } />
            </div>
        </div>
    );
};

export default MainButton;