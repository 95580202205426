import React, { Fragment } from 'react';

import Character from './LeftBar/Character';
import Characters from './LeftBar/Characters';
import Stash from './LeftBar/Stash';
import World from './LeftBar/World';
import Ladder from './LeftBar/Ladder';
import Hideaway from './LeftBar/Hideaway';
import Bulletshop from './LeftBar/Bulletshop';

import Splash from './Game/Splash';
import Login from './Main/Login';
import Registration from './Main/Registration';
import Airdrop from './Main/Airdrop';
import Settings from './Main/Settings';
import Profile from './Main/Profile';

import { GUIProperties } from 'ts/Properties';
import { RootState } from 'ts/Slices/Store';
import { useSelector } from 'react-redux';

interface ContentContainerProps {
    gameSendMessage:Function;
}

const ContentContainer: React.FC<ContentContainerProps> = (props) => {

    const windowId = useSelector((state : RootState) => state.gui.windowId);
    
    const gameLoading = useSelector((state : RootState) => state.game.gameLoading);
    
    let ContentComponent;
    switch(windowId) {
        case GUIProperties.Windows.Id.None:
            // Pause     
            ContentComponent = <noscript />;
            break;
        case GUIProperties.Windows.Id.Characters:
            // Characters
            ContentComponent = <Characters 
                    gameSendMessage = { props.gameSendMessage } />;
            break;
        case GUIProperties.Windows.Id.Character:
            // Character
            ContentComponent = <Character 
                    gameSendMessage = { props.gameSendMessage } />;
            break;
        case GUIProperties.Windows.Id.Stash:
            // Stash
            ContentComponent = <Stash />;
            break;
        case GUIProperties.Windows.Id.World:
            // World
            ContentComponent = <World />;
            break;
        case GUIProperties.Windows.Id.Ladder:
            // Ladder
            ContentComponent = <Ladder />;
            break;
        case GUIProperties.Windows.Id.Bulletshop:
            // Bulletshop
            ContentComponent = <Bulletshop />;
            break;
        case GUIProperties.Windows.Id.Hideaway:
            // Hideaway
            ContentComponent = <Hideaway />;
            break;
        case GUIProperties.Windows.Id.Settings:
            // Settings
            ContentComponent = <Settings />;
            break;
        case GUIProperties.Windows.Id.Profile:
            // Profile
            ContentComponent = <Profile />;
            break;
        case GUIProperties.Windows.Id.Airdrop:
            // Advert

            ContentComponent = <Airdrop />;
            break;
        case GUIProperties.Windows.Id.Login:
            // Login screen  
            ContentComponent = <Login 
                    gameSendMessage = { props.gameSendMessage } />;
            break;
        case GUIProperties.Windows.Id.Registration:
            // Registration
            ContentComponent = <Registration />;
            break;
        case GUIProperties.Windows.Id.Splash:
            if(gameLoading === false) {
                // Splash
                ContentComponent = <Splash />;
            } else {
                ContentComponent = <></>;
            }
            break;
        default:
            break;
    }


    return (
        <Fragment>
            { ContentComponent }
        </Fragment>
    );
}


export default ContentContainer;