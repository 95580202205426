import React, { Fragment } from 'react';

import { AppProperties } from 'ts/Properties';

import FullscreenButton from 'ts/Partials/Buttons/FullscreenButton';

import { useSelector } from 'react-redux';

import { Fade } from "react-awesome-reveal";

import { useTranslation } from 'react-i18next';
import { RootState } from 'ts/Slices/Store';

const Footer: React.FC = (props) => {

    const { t } = useTranslation("translations");

    const appState = useSelector((state: RootState) => state.gui.appState);
        
    return (
        <Fragment>
            { (appState === AppProperties.AppStates.WorldPause 
                    || appState === AppProperties.AppStates.Hideaway
                    || appState === AppProperties.AppStates.Characters
                    || appState === AppProperties.AppStates.Login) &&
                    <Fade>
                        <div className = { "flex w-full opacity-60 hover:opacity-100 h-8 z-70 items-end pb-1" }>
                            { (appState === AppProperties.AppStates.Login) &&
                                <Fragment>
                                    <div className = { "basis-3/12 text-left pl-2"}>
                                        <span className = { "text-white" }>
                                            { t('footer.version')} { AppProperties.version }
                                        </span>
                                    </div>
                                    <div className = { "basis-6/12 text-center"} >
                                        <span className = { "text-white" }>
                                            {t('footer.copyright')}
                                        </span>
                                    </div>
                                </Fragment>
                            }
                            { !(appState === AppProperties.AppStates.Login) &&
                                <div className="basis-9/12">
                                </div>
                            }
                            <div className="basis-3/12 flex justify-end items-end pr-2">
                                <FullscreenButton />
                            </div>
                        </div >
                    </Fade>
            }
        </Fragment>
    );
};

export default Footer;