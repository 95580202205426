import React from 'react';

import BulletIcon from 'ts/Icons/Crypto/BulletIcon';

import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';


import { RootState } from 'ts/Slices/Store';
import LeftBarWindow from 'ts/Components/Windows/LeftBarWindow';

const Stash:React.FC = (props) => {

    const { t } = useTranslation();

    const stash = useSelector((state: RootState) => state.account.stash);

    return (
        <LeftBarWindow title = { t('stash.title') }> 
            <div className="grid grid-cols-2 items-center">
                <span className="m-2 ml-auto flex">
                    <BulletIcon className={'h-6 fill-black '} /><span> : </span>
                </span>
                <span className = { "text-left m-2" }>
                    { stash.bullets }
                </span>
            </div>                      
            
        </LeftBarWindow>
    );
}

export default Stash;
