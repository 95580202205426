import React, { useState } from 'react';

import { AppProperties, GUIProperties } from 'ts/Properties';

import { logout } from 'ts/Services/AuthService';

import { guiActions } from 'ts/Slices/GuiSlice';
import { accountActions } from 'ts/Slices/AccountSlice';

import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { RootState } from 'ts/Slices/Store';
import ClickAwayListener from 'react-click-away-listener';
import HeaderButton from 'ts/Components/Buttons/HeaderButton';
import LogOutIcon from 'ts/Icons/Forms/LogOutIcon';
import CharactersIcon from 'ts/Icons/Main/CharactersIcon';
import ProfileIcon from 'ts/Icons/Forms/ProfileIcon';
import SettingsIcon from 'ts/Icons/Forms/SettingsIcon';
import MenuIcon from 'ts/Icons/Forms/MenuIcon';

interface ProfileMenuProps {
    gameSendMessage:Function;
}

const ProfileMenu: React.FC<ProfileMenuProps>  = (props) => {

    const dispatch = useDispatch();

    const [profileMenuOpen, setProfileMenuOpen] = useState(false);

    const handleToggleProfileMenu = () => {
        setProfileMenuOpen(!profileMenuOpen);
    };
  
    const handleCloseProfileMenu = () => {
        setProfileMenuOpen(false);
    };

    const handleToCharactersClick = async() => {
        dispatch(guiActions.setLoading(true));
        
        dispatch(accountActions.setSelectedCharacter(null));

        props.gameSendMessage("ToCharacters");
        props.gameSendMessage("CharactersUnselectCharacter");

        dispatch(guiActions.setWindowId(GUIProperties.Windows.Id.Characters));
        dispatch(guiActions.setAppState(AppProperties.AppStates.Characters));

        dispatch(guiActions.setLoading(false));
    };

    const handleLogoutClick = async() => {
        dispatch(guiActions.setLoading(true));

        await logout();

        
        props.gameSendMessage("ToLogin");

        dispatch(guiActions.setWindowId(GUIProperties.Windows.Id.Login));
        dispatch(guiActions.setAppState(AppProperties.AppStates.Login));

        dispatch(guiActions.setLoading(false));
    };

    const handleProfileClick = () => {
        dispatch(guiActions.setWindowId(GUIProperties.Windows.Id.Profile));
    };
        
    const handleSettingsOpenClick = () => {
        dispatch(guiActions.setWindowId(GUIProperties.Windows.Id.Settings));
    };
    
    
    const { t } = useTranslation("translations");

    const account = useSelector((state: RootState) => state.account.account);
    const appState = useSelector((state: RootState) => state.gui.appState);
    const email = account.email.length > 18 
            ? account.email.substring(0, 18) + '...' 
            : account.email;
    
    return (
        <div className = { "flex flex-col z-50" }>
            <HeaderButton additionalClassName = { "flex flex-row-reverse h-12 items-center" }  
                    handleClick = { handleToggleProfileMenu }>
                <div className="flex w-12 h-12 items-center justify-center">
                    <MenuIcon className = { "h-8 fill-black" }
                                opened = { profileMenuOpen } />   
                </div>
                { (profileMenuOpen === true && account != null) && 
                    <span className="mx-2">{ email }</span>
                }
            </HeaderButton>
            { profileMenuOpen === true &&
                <ClickAwayListener onClickAway = { handleCloseProfileMenu }>
                    <div className="flex flex-col">
                        <HeaderButton additionalClassName = { "flex h-12 items-center justify-between" }
                                handleClick = { handleProfileClick }>
                            <span  className = { "mx-2 text-left" }>
                                { t('profile.title') }
                            </span>
                            <div className="flex w-12 h-12 items-center justify-center">
                                <ProfileIcon className="h-8 fill-black" />
                            </div>
                        </HeaderButton>

                        <HeaderButton additionalClassName = { "flex h-12 items-center  justify-between" } 
                                handleClick = { handleSettingsOpenClick }>
                            <span className = { "mx-2 text-left" }>
                                { t('settings.title') }
                            </span>
                            <div className="flex w-12 h-12 items-center justify-center">
                                <SettingsIcon className="h-8 fill-black" />
                            </div>
                        </HeaderButton>


                        { (appState === AppProperties.AppStates.WorldPause 
                                || appState === AppProperties.AppStates.Hideaway) &&
                            <HeaderButton additionalClassName = { "flex h-12 items-center justify-between" } 
                                    handleClick = { handleToCharactersClick }>
                                <span className = { "mx-2 text-sm text-left" }>
                                    { t('app.button.characterSelection') }
                                </span>
                                <div className="flex w-12 h-12 items-center justify-center">
                                    <CharactersIcon className="h-8 fill-black" />
                                </div>
                            </HeaderButton>
                        }

                        <HeaderButton additionalClassName = { "flex h-12 items-center justify-between" }
                                handleClick = { handleLogoutClick }>
                            <span className = { "mx-2 text-left" }>
                                { t('app.logout') }
                            </span>
                            <div className="flex w-12 h-12 items-center justify-center">
                                <LogOutIcon className="h-8 fill-black" />
                            </div>
                        </HeaderButton>
                    </div>
                </ClickAwayListener>
            }
        </div>
    );
}

export default ProfileMenu;