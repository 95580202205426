import React from 'react';

import { GUIProperties } from 'ts/Properties';

import LanguageIcon from 'ts/Icons/Forms/LanguageIcon';

import i18n from 'ts/i18n';
import { guiActions } from 'ts/Slices/GuiSlice';
import { accountActions } from 'ts/Slices/AccountSlice';

import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'ts/Slices/Store';
import HeaderButton from 'ts/Components/Buttons/HeaderButton';

const LanguageButton: React.FC = () => {

    const dispatch = useDispatch();

    const settings  = useSelector((state: RootState) => state.account.settings);

    const handleLanguageChange = async() => {
        dispatch(guiActions.setLoading(true));

        let newSettings = { ...settings }
        if(settings.language === GUIProperties.Languages.Czech) {
            newSettings.language = GUIProperties.Languages.English;
            i18n.changeLanguage(GUIProperties.Languages.English);
        } else {
            newSettings.language = GUIProperties.Languages.Czech;
            i18n.changeLanguage(GUIProperties.Languages.Czech);
        }

        dispatch(accountActions.setSettings(newSettings));
        dispatch(guiActions.setLoading(false));
    };

    return (
        <HeaderButton additionalClassName = { "h-12 flex items-center justify-center opacity-80 hover:opacity-100" }
                handleClick = { handleLanguageChange }>
            <LanguageIcon language = { settings.language } className= { "h-11" } />
        </HeaderButton>
    );
};

export default LanguageButton; 