import React from 'react';

interface ContinueIconProps {
    className: string;
}

const ContinueIcon:React.FC<ContinueIconProps> = (props) => {
    return (    
        <svg className={props.className} viewBox="0 0 92 92" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M14.375 11.5H23V80.5H14.375V11.5ZM42.757 13.7425L35.9375 17.25V74.75L42.757 78.2575L83.007 49.5075V42.4925L42.757 13.7425ZM73.0825 46L44.5625 66.3723V25.6277L73.0825 46Z" fill="black"/>
        </svg>

    );
}

export default ContinueIcon;