import React, { Fragment } from 'react';

import { AppProperties, GUIProperties } from 'ts/Properties';

import AdvertButton from 'ts/Partials/Buttons/AirdropButton';
import ProfileMenu from 'ts/Partials/Header/ProfileMenu';
import LanguageButton from 'ts/Partials/Buttons/LanguageButton';

import { guiActions } from 'ts/Slices/GuiSlice';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'ts/Slices/Store';

import { useTranslation } from 'react-i18next';
import PauseIcon from 'ts/Icons/Main/PauseIcon';
import HeaderButton from 'ts/Components/Buttons/HeaderButton';
import MainRouteMenu from './MainRouteMenu';
import CharactersIcon from 'ts/Icons/Main/CharactersIcon';
import LadderIcon from 'ts/Icons/Main/LadderIcon';
import StashIcon from 'ts/Icons/Main/StashIcon';
import CharacterIcon from 'ts/Icons/Main/CharacterIcon';
import WorldIcon from 'ts/Icons/Main/WorldIcon';
import HideawayIcon from 'ts/Icons/Main/HideawayIcon';
import IconHeaderButton from 'ts/Components/Buttons/IconHeaderButton';

interface HeaderProps {
    gameSendMessage:Function;
}

const Header:React.FC<HeaderProps> = (props) => {

    const { t } = useTranslation("translations");
    //const account = useSelector((state) => state.account.account);
    const appState = useSelector((state: RootState) => state.gui.appState);
    //const settings  = useSelector((state) => state.account.settings);

    const dispatch = useDispatch();

    const handleMenuCharactersClick = () => {
        dispatch(guiActions.setWindowId(GUIProperties.Windows.Id.Characters));
    };

    const handleMenuLadderClick = () => {
        dispatch(guiActions.setWindowId(GUIProperties.Windows.Id.Ladder));
    };


    const handleMenuCharacterClick = () => {
        dispatch(guiActions.setWindowId(GUIProperties.Windows.Id.Character));
    };

    const handleMenuStashClick = () => {
        dispatch(guiActions.setWindowId(GUIProperties.Windows.Id.Stash));
    };

    const handleMenuWorldClick = () => {
        dispatch(guiActions.setWindowId(GUIProperties.Windows.Id.World));
    };

    const handleMenuHideawayClick = () => {
        dispatch(guiActions.setWindowId(GUIProperties.Windows.Id.Hideaway));
    };
/*
    const handleMenuBulletshopClick = () => {
        dispatch(guiActions.setWindowId(GUIProperties.Windows.Id.Bulletshop));
    };
*/
    const handleMenuAirdropClick = () => {
        dispatch(guiActions.setWindowId(GUIProperties.Windows.Id.Airdrop));
    };

    const handleWorldPauseButtonClick = () => {
        dispatch(guiActions.setWindowId(GUIProperties.Windows.Id.None));
        dispatch(guiActions.setAppState(AppProperties.AppStates.WorldPause));

        props.gameSendMessage("PauseGame");
    };


    return ( 
        <div className = { "h-12 w-full flex justify-between z-40" }>
            <div className="flex">
                { (appState === AppProperties.AppStates.Login) &&
                    <MainRouteMenu />
                }
                { (appState === AppProperties.AppStates.Characters) &&
                    <Fragment>
                        <AdvertButton 
                               handleMenuAdvertClick = { handleMenuAirdropClick } /> 
                        <MainRouteMenu />
                        <IconHeaderButton text = { t('characters.title') }
                                handleClick = { handleMenuCharactersClick }
                                icon = { <CharactersIcon className="h-8 shrink-0 " /> } /> 
                        <IconHeaderButton text = { t('ladder.title') }
                                handleClick = { handleMenuLadderClick }
                                icon = { <LadderIcon className="h-8 shrink-0 " /> } /> 
                        <IconHeaderButton text = { t('stash.title') }
                                handleClick = { handleMenuStashClick }
                                icon = { <StashIcon className="h-8 shrink-0 " /> } /> 
                    </Fragment>
                }
                { (appState === AppProperties.AppStates.Hideaway) &&
                    <Fragment>
                        <AdvertButton
                                handleMenuAdvertClick = { handleMenuAirdropClick } /> 
                        <MainRouteMenu />

                        <IconHeaderButton text = { t('character.title') }
                                handleClick = { handleMenuCharacterClick }
                                icon = { <CharacterIcon className="h-8 shrink-0 " /> } /> 
                        <IconHeaderButton text = { t('stash.title') }
                                handleClick = { handleMenuStashClick }
                                icon = { <StashIcon className="h-8 shrink-0 " /> } /> 
                        <IconHeaderButton text = { t('world.title') }
                                handleClick = { handleMenuWorldClick }
                                icon = { <WorldIcon className="h-8 shrink-0 " /> } /> 
                        <IconHeaderButton text = { t('hideaway.title') }
                                handleClick = { handleMenuHideawayClick }
                                icon = { <HideawayIcon className="h-8 shrink-0 " /> } /> 
                                
                    </Fragment>
                }
            </div>
            <div>
                { (appState === AppProperties.AppStates.Login) &&
                    <LanguageButton />
                }
                { (appState === AppProperties.AppStates.Hideaway
                        || appState === AppProperties.AppStates.Characters
                        || appState === AppProperties.AppStates.WorldPause) &&
                    <ProfileMenu 
                            gameSendMessage = { props.gameSendMessage } />
                }
                { (appState === AppProperties.AppStates.WorldPlay) &&
                    <HeaderButton additionalClassName = { "h-full flex items-center justify-center" }
                            handleClick = { handleWorldPauseButtonClick }>
                        <PauseIcon className={'h-10 text-black'} />
                    </HeaderButton>
                }
            </div>
        </div>
    );
    
}

export default Header;