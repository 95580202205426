import React from 'react';

interface DeadFundIconProps {
    className: string;
}

const DeadFundIcon:React.FC<DeadFundIconProps> = (props) => {
    return (   
      <svg 
            className = { props.className }
            viewBox="-15 0 97 112" 
            fill="none" 
            xmlns="http://www.w3.org/2000/svg"
      >
         <path fillRule="evenodd" clipRule="evenodd" d="M15.6642 22.2958L14.6844 32.9407L13.2005 31.8202L10.5628 29.9633L10.6189 32.7015C10.6189 32.7015 10.6189 35.7068 7.52835 36.0503C4.43785 36.3939 3.32249 35.3131 3.32249 35.3131C3.32249 35.3131 1.36544 29.2344 1.18359 27.1137" fill="#4F601A"/>
         <path fillRule="evenodd" clipRule="evenodd" d="M15.6636 22.4581L14.6838 32.9406L13.1999 31.8202L10.5622 29.9633L10.6183 32.7014C10.6183 32.7014 10.6183 35.7067 7.52777 36.0503C4.43726 36.3939 3.32191 35.3131 3.32191 35.3131C3.32191 35.3131 1.58907 33.7906 1.40723 31.6699" fill="#4F601A"/>
         <path d="M15.6636 22.4581L14.6838 32.9406L13.1999 31.8202L10.5622 29.9633L10.6183 32.7014C10.6183 32.7014 10.6183 35.7067 7.52777 36.0503C4.43726 36.3939 3.32191 35.3131 3.32191 35.3131C3.32191 35.3131 1.58907 33.7906 1.40723 31.6699" stroke="black" stroke-width="0.999611"/>
         <path fillRule="evenodd" clipRule="evenodd" d="M1.13086 28.369L1.18206 24.5705C1.18206 24.5705 1.60887 21.3986 3.23798 20.9427C4.86709 20.4869 12.0125 20.1495 12.0125 20.1495C12.0125 20.1495 14.1496 20.1232 15.6649 22.416" fill="#4F601A"/>
         <path d="M1.13086 28.369L1.18206 24.5705C1.18206 24.5705 1.60887 21.3986 3.23798 20.9427C4.86709 20.4869 12.0125 20.1495 12.0125 20.1495C12.0125 20.1495 14.1496 20.1232 15.6649 22.416" stroke="black" stroke-width="0.999611"/>
         <path fillRule="evenodd" clipRule="evenodd" d="M10.5662 30.1403L10.6183 30.1223V32.7014C10.6183 32.7014 10.6183 35.7067 7.52777 36.0503C4.43726 36.3939 3.32191 35.313 3.32191 35.313C3.32191 35.313 1.58907 33.7906 1.40723 31.6698" fill="#4F601A"/>
         <path d="M10.5662 30.1403L10.6183 30.1223V32.7014C10.6183 32.7014 10.6183 35.7067 7.52777 36.0503C4.43726 36.3939 3.32191 35.313 3.32191 35.313C3.32191 35.313 1.58907 33.7906 1.40723 31.6698" stroke="black" stroke-width="0.999611"/>
         <path d="M46.4082 84.5708L61.9318 83.4341V86.4653L64.5359 85.5181L66.2383 83.3393L72.5482 82.0131" fill="#4F601A"/>
         <path d="M25.0762 62.3731L25.1759 60.9521L50.8149 62.7522C50.8149 62.7522 54.0325 62.5096 54.5525 59.4029C55.0724 56.2961 57.4249 30.261 57.4249 30.261C57.2246 30.4506 46.4634 19.5811 45.9077 17.6833C45.352 15.7855 43.9983 14.9422 46.1081 12.2838C48.2179 9.62542 51.0153 11.0527 51.0153 11.0527C51.1226 10.9179 54.801 13.0987 54.6209 13.3258L62.3326 19.7673L66.3385 24.5986L66.4634 26.0701L70.645 34.4498L72.7486 46.3855C72.7486 46.3855 72.8483 70.0669 72.7486 70.1617C72.6488 70.2565 72.4105 82.4648 72.4105 82.4648L46.7088 84.6548C45.661 84.8531 46.4082 71.6775 46.4082 71.6775L43.9048 71.488L37.5949 69.9722L31.7859 67.2253L25.3763 62.4887L25.1759 60.8786" fill="#4F601A"/>
         <path d="M58.4375 16.4851C58.4375 16.4851 64.733 22.0729 65.9223 24.128C66.5566 25.2241 66.2599 25.8501 66.7111 26.996C67.454 28.883 69.5557 31.5782 70.4711 34.2018C71.4661 37.0531 71.9232 40.9291 72.2587 43.5409C72.4982 45.4053 72.5016 46.031 72.5791 48.368C72.784 54.5451 72.5791 82.157 72.5791 82.157" stroke="black" stroke-width="0.999611"/>
         <path d="M46.2849 84.6752C46.2849 84.6752 46.5351 73.9662 46.4222 72.3438C46.3942 71.9424 46.5079 71.7645 46.3092 71.5966C45.9326 71.2783 44.6592 71.7293 43.4442 71.5164C41.144 71.1134 36.8003 69.8368 33.7481 68.3057C30.5682 66.7105 24.7939 62.0195 24.7939 62.0195" stroke="black" stroke-width="0.999611"/>
         <path fillRule="evenodd" clipRule="evenodd" d="M28.6056 44.0218L24.9983 62.0069C24.9983 62.0069 23.7324 65.2836 19.4683 65.7248C15.2042 66.1661 6.27605 57.2174 6.27605 57.2174C6.27605 57.2174 4.21086 53.7516 6.14276 50.7897C8.07465 47.8278 11.14 47.7648 11.14 47.7648L8.48116 45.4474C8.48116 45.4474 6.55633 43.3075 6.67903 40.976C6.80173 38.6444 8.16249 37.0677 8.16249 37.0677C8.16249 37.0677 9.63447 34.7286 14.6304 35.5665" fill="#4F601A" fill-opacity="0.91"/>
         <path d="M28.6056 44.0218L24.9983 62.0069C24.9983 62.0069 23.7324 65.2836 19.4683 65.7248C15.2042 66.1661 6.27605 57.2174 6.27605 57.2174C6.27605 57.2174 4.21086 53.7516 6.14276 50.7897C8.07465 47.8278 11.14 47.7648 11.14 47.7648L8.48116 45.4474C8.48116 45.4474 6.55633 43.3075 6.67903 40.976C6.80173 38.6444 8.16249 37.0677 8.16249 37.0677C8.16249 37.0677 9.63447 34.7286 14.6304 35.5665" stroke="black" stroke-width="0.999611"/>
         <path fillRule="evenodd" clipRule="evenodd" d="M16.1992 18.5887L11.4972 13.9243C11.4972 13.9243 10.1413 13.2059 10.7919 12.0771C11.2125 11.3474 13.531 8.89183 15.2396 7.50419C16.1749 6.74482 17.5193 6.60205 17.5193 6.60205" fill="#4F601A" fill-opacity="0.91"/>
         <path d="M16.1992 18.5887L11.4972 13.9244C11.4972 13.9244 10.1413 13.206 10.7919 12.0772C11.4425 10.9483 11.9854 10.538 11.9854 10.538" stroke="black" stroke-width="0.999611"/>
         <path d="M14.6436 8.12628L15.9227 7.03795C15.9227 7.03795 16.5194 6.47354 17.3333 6.62759" stroke="black" stroke-width="0.999611"/>
         <path d="M16.9757 9.99316L13.1242 13.4623C13.1242 13.4623 12.5275 14.078 11.6055 13.9244" stroke="black" stroke-width="0.999611"/>
         <path d="M9.78048 8.35042L12.7448 11.205C12.7448 11.205 13.5587 12.0771 14.6435 11.205C15.7284 10.333 15.6949 9.50679 15.4985 9.30558C15.442 9.24755 14.8492 8.50321 14.5195 8.15672C13.8875 7.4917 12.5466 6.52528 12.7403 6.05814C13.3728 4.53273 12.3978 3.16845 11.192 2.91213C9.98616 2.65581 9.02132 3.65605 9.02132 3.65605L8.86861 4.70764L7.83226 4.73979C7.83226 4.73979 6.92347 5.23365 6.89434 6.42509C6.86521 7.61653 7.16578 8.58003 10.2576 8.52868" fill="#C9C0C0"/>
         <path d="M9.78048 8.35042L12.7448 11.205C12.7448 11.205 13.5586 12.0771 14.6435 11.205C15.7284 10.333 15.6949 9.50679 15.4985 9.30558C15.442 9.24755 14.8492 8.50321 14.5195 8.15672C13.8875 7.4917 12.5466 6.52528 12.7403 6.05814C13.3728 4.53273 12.3978 3.16845 11.192 2.91213C9.98616 2.65581 9.02132 3.65605 9.02132 3.65605L8.86861 4.70764L7.83226 4.73979C7.83226 4.73979 6.92347 5.23365 6.89434 6.42509C6.86521 7.61653 7.16578 8.58003 10.2576 8.52868" stroke="black" stroke-width="0.999611"/>
         <path d="M23.412 1.54114L54.1687 4.15863C54.1687 4.15863 59.7137 4.15863 59.3875 7.70665C59.361 7.99553 59.4056 12.0032 58.5878 16.4659C58.484 17.0315 55.4831 13.6559 54.238 12.8168C53.5786 12.3726 52.4226 11.3803 50.5084 10.6727C49.4354 10.2761 45.6016 11.0113 44.8857 13.949C44.59 15.1621 45.1157 16.4454 45.9812 17.8798C49.4712 23.6617 57.3404 30.1298 57.3404 30.1298L54.6578 59.5376C54.6578 59.5376 53.1902 62.9311 50.5808 62.7771C47.9714 62.6231 25.3011 60.9257 25.3011 60.9257C25.3011 60.9257 25.7235 57.3363 24.315 55.8351C22.9066 54.3339 20.0266 52.4453 20.0266 52.4453C20.0266 52.4453 23.2809 52.4382 24.756 50.9028C26.231 49.3674 26.932 46.6255 25.9534 44.7744C24.9749 42.9234 24.2025 42.4625 23.1326 41.5454C22.0627 40.6282 15.1892 35.7819 14.7001 35.6274C14.2111 35.4729 17.6357 5.54711 17.6357 5.54711" fill="#FFD890"/>
         <path d="M23.412 1.54114L54.1687 4.15863C54.1687 4.15863 59.7137 4.15863 59.3875 7.70665C59.361 7.99553 59.4056 12.0032 58.5878 16.4659C58.484 17.0315 55.4831 13.6559 54.238 12.8168C53.5786 12.3726 52.4226 11.3803 50.5084 10.6727C49.4354 10.2761 45.6016 11.0113 44.8857 13.949C44.59 15.1621 45.1157 16.4454 45.9812 17.8798C49.4712 23.6617 57.3404 30.1298 57.3404 30.1298L54.6578 59.5376C54.6578 59.5376 53.1902 62.9311 50.5808 62.7771C47.9714 62.6231 25.3011 60.9257 25.3011 60.9257C25.3011 60.9257 25.7235 57.3363 24.315 55.8351C22.9066 54.3339 20.0266 52.4453 20.0266 52.4453C20.0266 52.4453 23.2809 52.4382 24.756 50.9028C26.231 49.3674 26.932 46.6255 25.9534 44.7744C24.9749 42.9234 24.2025 42.4625 23.1326 41.5454C22.0627 40.6282 15.1892 35.7819 14.7001 35.6274C14.2111 35.4729 17.6357 5.54712 17.6357 5.54712" stroke="black" stroke-width="0.999611"/>
         <path d="M17.5986 5.61891L17.8621 4.225C17.8621 4.225 18.9148 0.489959 23.9169 1.63506" fill="#FFD890"/>
         <path d="M17.5986 5.61891L17.8621 4.225C17.8621 4.225 18.9148 0.489959 23.9169 1.63506" stroke="black" stroke-width="0.999611"/>
         <path d="M39.4331 8.25056L44.1761 8.58077C44.1761 8.58077 47.5469 8.56575 47.5301 9.67035C47.5208 10.2865 47.5297 10.3746 47.4935 10.9574C47.4935 10.9574 46.6531 11.4901 45.5064 12.7963C44.6695 13.7498 44.768 15.3654 45.329 16.8102C45.652 17.6426 46.7881 18.698 46.7255 19.4528C46.5392 21.7046 46.3909 23.4562 46.3596 23.4266C46.2717 23.3435 46.3596 25.5828 44.606 25.5828C42.8524 25.5828 37.3869 25.1056 36.8903 24.9474C36.3938 24.7892 34.7417 24.062 34.8737 22.4314C35.0057 20.8007 36.0138 10.241 36.0138 10.241C36.0138 10.241 36.1532 7.85105 39.573 8.26559" fill="#424242"/>
         <path d="M39.4331 8.25056L44.1761 8.58077C44.1761 8.58077 47.5469 8.56575 47.5301 9.67035C47.5208 10.2865 47.5297 10.3746 47.4935 10.9574C47.4935 10.9574 46.6531 11.4901 45.5064 12.7963C44.6695 13.7498 44.768 15.3654 45.329 16.8102C45.652 17.6426 46.7881 18.698 46.7255 19.4528C46.5392 21.7046 46.3909 23.4562 46.3596 23.4266C46.2717 23.3435 46.3596 25.5828 44.606 25.5828C42.8524 25.5828 37.3869 25.1056 36.8903 24.9474C36.3938 24.7892 34.7417 24.062 34.8737 22.4314C35.0057 20.8007 36.0138 10.241 36.0138 10.241C36.0138 10.241 36.1532 7.85105 39.573 8.26559" stroke="black" stroke-width="0.343402"/>
         <path d="M25.2778 7.84311L25.3246 7.81138L27.5125 7.96918C27.5125 7.96918 27.0119 14.2787 26.9788 14.31C26.9457 14.3413 24.644 14.1522 24.6104 14.1522C24.5769 14.1522 25.3546 7.62561 25.3546 7.62561" fill="#A0A09E"/>
         <path d="M25.2778 7.84311L25.3246 7.81138L27.5125 7.96918C27.5125 7.96918 27.0119 14.2787 26.9788 14.31C26.9457 14.3413 24.644 14.1522 24.6104 14.1522C24.5769 14.1522 25.3546 7.62561 25.3546 7.62561" stroke="black" stroke-width="0.343402"/>
         <path d="M24.353 16.0171L24.3998 15.9853L26.5877 16.1431C26.5877 16.1431 26.0871 22.4527 26.054 22.484C26.0209 22.5153 23.7192 22.3262 23.6856 22.3262C23.6521 22.3262 24.4298 15.7996 24.4298 15.7996" fill="#A0A09E"/>
         <path d="M24.353 16.0171L24.3998 15.9853L26.5877 16.1431C26.5877 16.1431 26.0871 22.4527 26.054 22.484C26.0209 22.5153 23.7192 22.3262 23.6856 22.3262C23.6521 22.3262 24.4298 15.7996 24.4298 15.7996" stroke="black" stroke-width="0.343402"/>
         <path d="M23.5606 24.2619L23.6065 24.2298L25.7454 24.3893C25.7454 24.3893 25.2564 30.7564 25.2237 30.7881C25.1915 30.8195 22.9409 30.6287 22.9082 30.6287C22.8756 30.6287 23.6356 24.0424 23.6356 24.0424" fill="#A0A09E"/>
         <path d="M23.5606 24.2619L23.6065 24.2298L25.7454 24.3893C25.7454 24.3893 25.2564 30.7564 25.2237 30.7881C25.1915 30.8195 22.9409 30.6287 22.9082 30.6287C22.8756 30.6287 23.6356 24.0424 23.6356 24.0424" stroke="black" stroke-width="0.343402"/>
         <path d="M22.7774 32.5218L22.8237 32.4905L24.979 32.6462C24.979 32.6462 24.4859 38.8593 24.4533 38.8902C24.4206 38.9206 22.1533 38.7345 22.1202 38.7345C22.0871 38.7345 22.8533 32.3076 22.8533 32.3076" fill="#A0A09E"/>
         <path d="M22.7774 32.5218L22.8237 32.4905L24.979 32.6462C24.979 32.6462 24.4859 38.8593 24.4533 38.8902C24.4206 38.9206 22.1533 38.7345 22.1202 38.7345C22.0871 38.7345 22.8533 32.3076 22.8533 32.3076" stroke="black" stroke-width="0.343402"/>
         <path d="M38.8507 49.25L43.6056 49.4942L42.9493 57.9299L38.2773 57.4915L39.0881 49.1656" fill="#ABACA8"/>
         <path d="M38.8507 49.25L43.6056 49.4942L42.9493 57.9299L38.2773 57.4915L39.0881 49.1656" stroke="black" stroke-width="0.343402"/>
         <path d="M47.1846 58.7193L50.5068 23.7075L47.1846 58.7193Z" fill="#D8D8D8"/>
         <path d="M47.1846 58.7193L50.5068 23.7075" stroke="black" stroke-width="0.343402"/>
         <path d="M51.6729 11.0721L51.8529 9.17102L51.6729 11.0721Z" fill="#D8D8D8"/>
         <path d="M51.6729 11.0721L51.8529 9.17102" stroke="black" stroke-width="0.343402"/>
         <path d="M19.3931 60.7666C19.5035 60.6689 22.0458 57.9842 22.0458 57.9842C22.0458 57.9842 19.4924 55.6034 19.4567 55.6034C19.4209 55.6034 17.4069 54.3109 16.0762 54.9229C14.7454 55.5349 13.2713 56.5218 13.0192 58.0865C12.7672 59.6511 13.4151 60.8755 13.6305 61.0454C13.8459 61.2154 16.2218 63.5239 16.2218 63.5239" fill="#C2BF99"/>
         <path d="M19.3931 60.7666C19.5035 60.6689 22.0458 57.9842 22.0458 57.9842C22.0458 57.9842 19.4924 55.6034 19.4567 55.6034C19.4209 55.6034 17.4069 54.3109 16.0762 54.9229C14.7454 55.5349 13.2713 56.5218 13.0192 58.0865C12.7672 59.6511 13.4151 60.8755 13.6305 61.0454C13.8459 61.2154 16.2218 63.5239 16.2218 63.5239" stroke="black" stroke-width="0.999611" stroke-linecap="round"/>
         <path d="M16.2207 63.5118L16.8563 62.8906L16.7729 62.2356C16.7729 62.2356 16.6148 61.7677 16.9803 61.8094C17.3458 61.8512 18.1243 62.1747 18.1371 62.1572C18.1499 62.1396 18.089 61.0442 18.089 61.0442C18.089 61.0442 17.9933 60.6927 18.4095 60.919C18.8257 61.1452 19.3849 60.7637 19.3849 60.7637" fill="#C2BF99"/>
         <path d="M16.2207 63.5118L16.8563 62.8906L16.7729 62.2356C16.7729 62.2356 16.6148 61.7677 16.9803 61.8094C17.3458 61.8512 18.1243 62.1747 18.1371 62.1572C18.1499 62.1396 18.089 61.0442 18.089 61.0442C18.089 61.0442 17.9933 60.6927 18.4095 60.919C18.8257 61.1452 19.3849 60.7637 19.3849 60.7637" stroke="black" stroke-width="0.999611"/>
         <path d="M20.5006 47.8385C20.5967 47.7302 22.4669 44.9488 22.4669 44.9488C22.4669 44.9488 19.8773 42.8934 19.8417 42.8972C19.8062 42.9009 17.8653 41.7912 16.628 42.5358C15.3904 43.2804 14.0783 44.1218 14.0476 45.6984C14.0172 47.2745 14.7778 48.4029 15.0159 48.5485C15.2537 48.6946 17.8682 50.6895 17.8682 50.6895" fill="#C2BF99"/>
         <path d="M20.5006 47.8385C20.5967 47.7302 22.4669 44.9488 22.4669 44.9488C22.4669 44.9488 19.8773 42.8934 19.8417 42.8972C19.8062 42.9009 17.8653 41.7912 16.628 42.5358C15.3904 43.2804 14.0783 44.1218 14.0476 45.6984C14.0172 47.2745 14.7778 48.4029 15.0159 48.5485C15.2537 48.6946 17.8682 50.6895 17.8682 50.6895" stroke="black" stroke-width="0.999666" stroke-linecap="round"/>
         <path d="M17.7842 50.7182C17.7842 50.7182 18.471 50.3445 18.4284 50.0444C18.3881 49.7605 18.1964 49.4193 18.1964 49.4193C18.1964 49.4193 17.9937 48.9878 18.3527 48.9931C18.7108 48.9985 19.4935 49.1763 19.5547 49.13C19.6159 49.0837 19.3112 48.2839 19.3112 48.2839C19.3112 48.2839 19.1913 47.6841 19.6247 47.8702C20.0586 48.0563 20.5505 47.8046 20.5505 47.8046" fill="#C2BF99"/>
         <path d="M17.7842 50.7182C17.7842 50.7182 18.471 50.3445 18.4284 50.0444C18.3881 49.7605 18.1964 49.4193 18.1964 49.4193C18.1964 49.4193 17.9937 48.9878 18.3527 48.9931C18.7108 48.9985 19.4935 49.1763 19.5547 49.13C19.6159 49.0837 19.3112 48.2839 19.3112 48.2839C19.3112 48.2839 19.1913 47.6841 19.6247 47.8702C20.0586 48.0563 20.5505 47.8046 20.5505 47.8046" stroke="black" stroke-width="0.999666"/>
         <path d="M1.0843 31.7484C1.06488 31.1468 1 28.703 1 28.703C1 28.703 1.50184 27.1897 2.82288 27.0285C4.14391 26.8674 4.49656 26.9283 4.59322 26.9672C4.68989 27.006 6.31238 27.4214 6.54543 28.89C6.77847 30.3586 6.67607 31.3426 6.54543 31.3893C6.41478 31.4361 4.019 31.4353 4.019 31.4353H3.33663C3.33663 31.4353 3.27925 31.1084 3.21393 30.8926C3.14861 30.6767 3.07534 30.5728 2.91247 30.8771C2.7496 31.1814 2.65559 31.4511 2.65559 31.4511L2.50155 31.4586C2.50155 31.4586 2.44373 30.9852 2.3868 30.8846C2.32986 30.784 2.2076 30.5999 2.05709 30.8926C1.90658 31.1852 1.49257 31.6194 1.49257 31.6194" fill="#C2BF99"/>
         <path d="M1.0843 31.7484C1.06488 31.1468 1 28.703 1 28.703C1 28.703 1.50184 27.1897 2.82288 27.0285C4.14391 26.8674 4.49656 26.9283 4.59322 26.9672C4.68989 27.006 6.31238 27.4214 6.54543 28.89C6.77847 30.3586 6.67607 31.3426 6.54543 31.3893C6.41478 31.4361 4.019 31.4353 4.019 31.4353H3.33663C3.33663 31.4353 3.27925 31.1084 3.21393 30.8926C3.14861 30.6767 3.07534 30.5728 2.91247 30.8771C2.7496 31.1814 2.65559 31.4511 2.65559 31.4511L2.50155 31.4586C2.50155 31.4586 2.44373 30.9852 2.3868 30.8846C2.32986 30.784 2.2076 30.5999 2.05709 30.8926C1.90658 31.1852 1.49257 31.6194 1.49257 31.6194" stroke="black" stroke-width="0.999611"/>
         <path d="M63.5859 87.2216L66.774 87.3665L66.8225 101.778C66.8225 101.778 66.962 104.138 70.7208 104.525C74.4795 104.913 74.5612 101.54 74.5612 101.54C74.5612 101.54 74.6507 87.1373 74.6163 87.1373C74.5819 87.1373 76.6903 86.9148 76.6903 86.9148" fill="#869947"/>
         <path d="M63.5859 87.2216L66.774 87.3665L66.8225 101.778C66.8225 101.778 66.962 104.138 70.7208 104.525C74.4795 104.913 74.5612 101.54 74.5612 101.54C74.5612 101.54 74.6507 87.1373 74.6163 87.1373C74.5819 87.1373 76.6903 86.9148 76.6903 86.9148" stroke="black" stroke-width="0.999611"/>
         <path d="M40.9199 87.3356C40.9199 87.3356 43.1197 87.3711 43.1458 87.3711C43.1718 87.3711 43.5302 91.0352 46.5219 91.0844C49.5135 91.1337 50.4152 89.3678 50.4152 89.3678L50.8385 87.3694C50.8385 87.3694 56.0948 87.3694 56.1209 87.3694C56.1469 87.3694 56.248 91.0515 59.0834 91.0026C61.9188 90.9538 62.4828 90.2357 62.4828 90.2357C62.4828 90.2357 64.9152 87.0572 63.8189 86.8401" fill="#869947"/>
         <path d="M40.9199 87.3356C40.9199 87.3356 43.1197 87.3711 43.1458 87.3711C43.1718 87.3711 43.5302 91.0352 46.5219 91.0844C49.5135 91.1337 50.4152 89.3678 50.4152 89.3678L50.8385 87.3694C50.8385 87.3694 56.0948 87.3694 56.1209 87.3694C56.1469 87.3694 56.248 91.0515 59.0834 91.0026C61.9188 90.9538 62.4828 90.2357 62.4828 90.2357C62.4828 90.2357 64.9152 87.0572 63.8189 86.8401" stroke="black" stroke-width="0.999611"/>
         <path d="M40.8616 87.3427L40.3682 85.2717C40.3682 85.2717 61.988 83.4365 61.935 83.3885C61.8821 83.3401 62.0847 86.4907 62.0847 86.4907C62.0847 86.4907 64.25 85.7776 64.6972 85.3685C65.1443 84.9594 66.1877 83.3488 66.8219 83.1952C67.4562 83.0416 72.4009 81.9495 73.4302 82.0985C74.4595 82.2476 76.3305 82.9276 76.3141 82.9535L76.7431 87.1402" fill="#869947"/>
         <path d="M40.8616 87.3427L40.3682 85.2717C40.3682 85.2717 61.988 83.4365 61.935 83.3885C61.8821 83.3401 62.0847 86.4907 62.0847 86.4907C62.0847 86.4907 64.25 85.7776 64.6972 85.3685C65.1443 84.9594 66.1877 83.3488 66.8219 83.1952C67.4562 83.0416 72.4009 81.9495 73.4302 82.0985C74.4595 82.2476 76.3305 82.9276 76.3141 82.9535L76.7431 87.1402" stroke="black" stroke-width="0.999611"/>
         <path d="M39.585 110.921L39.7606 87.4187L43.3053 87.4237C43.3053 87.4237 43.5701 90.7442 46.3248 91.0769C49.0794 91.4096 50.3682 89.3586 50.3682 89.4141C50.3682 89.4696 50.7332 87.5247 50.7787 87.3076C50.8241 87.0905 54.3405 87.2441 56.155 87.331C56.3841 87.3418 55.9357 90.8552 59.4521 91.0213C62.9685 91.1875 63.3539 88.3947 63.3539 88.3947C63.3539 88.3947 63.5679 87.5794 63.6037 87.3218C63.6394 87.0642 66.9232 87.2416 66.9232 87.3527C66.9232 87.4637 66.8848 101.445 66.9537 101.719C67.0115 101.949 67.3911 104.311 70.4697 104.436C73.5483 104.561 74.0704 103.471 74.4072 102.028C74.665 100.923 74.5723 87.1966 74.5723 87.1966L79.4949 87.4187L79.9054 111.136" fill="url(#paint0_linear_401_333)"/>
         <path d="M39.585 110.921L39.7606 87.4187L43.3053 87.4237C43.3053 87.4237 43.5701 90.7442 46.3248 91.0769C49.0794 91.4096 50.3682 89.3586 50.3682 89.4141C50.3682 89.4696 50.7332 87.5247 50.7787 87.3076C50.8241 87.0905 54.3405 87.2441 56.155 87.331C56.3841 87.3418 55.9357 90.8552 59.4521 91.0213C62.9685 91.1875 63.3539 88.3947 63.3539 88.3947C63.3539 88.3947 63.5679 87.5794 63.6037 87.3218C63.6394 87.0642 66.9232 87.2416 66.9232 87.3527C66.9232 87.4637 66.8848 101.445 66.9537 101.719C67.0115 101.949 67.3911 104.311 70.4697 104.436C73.5483 104.561 74.0704 103.471 74.4072 102.028C74.665 100.923 74.5723 87.1966 74.5723 87.1966L79.4949 87.4187L79.9054 111.136" stroke="black" stroke-width="0.999611"/>
         <path d="M39.4404 110.686L80.0579 110.941L39.4404 110.686Z" fill="#D8D8D8"/>
         <path d="M39.4404 110.686L80.0579 110.941" stroke="black" stroke-width="0.999611"/>
      </svg>
    );
}

export default DeadFundIcon;